export const questions = [
  {
    question: "တစ်နေ့တာ ဟောစာတန်း",
    cards: [
      { name: "The Fool",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/00.JPG", description: "လုပ်ငန်းများရှေ့သို့မတိုးဘဲ နှောင့်နှေးနေမည်။ အရောင်းအဝယ်သမားဖြစ်ပါက အရောင်းအဝယ် မကောင်းပါ။ အိမ်သို့ အရက်ပုလင်းများ ရောက်လာတတ်သည်။ အမြန်ဆုံး ပြန်၍ ထုတ်ပစ်ပါ။ နားအူ၍နေမည်။ အမြန်ဆုံး ကုသပါ။ ပျောက်ကင်းမည်။ ခရီးတိုများ ဆက်တိုက်သွားနေရမည်။ အမှုအခင်း ဖြစ်ပွားတတ်သည်။ ကိုယ်ခန္ဓာ၏ ဘယ်ဘက်ခြမ်း မကြာခဏအောင့်မည်။ သက်ကြီးရွယ်အိုများ အိမ်၌ လာရောက် မတည်းခိုမိရန် သတိပြုပါ။ တည်းခိုမိပါက ထိုသက်ကြီးရွယ်အို အိမ်၌ ဆုံးပါးသွားတတ်သည်။" },
       { name: "The Magician",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/01.JPG", description: "မကြာခင် ခရီးတစ်ခုသွားရမည်။ ထိုခရီးသည် ကောင်းသော ခရီးဖြစ်သည်။ မိတ်ဆွေတစ်ဦး ၏လိမ်လည် လှည့်ဖျားမှုကို ခံရမည်။ ရပ်ဝေးမှဆွေးမျိုးရောက်လာမည်။ မိမိ ကိုကောင်း ကျိုးမပေးသောဆရာ မိမိထံရောက်လာမည်။ ငွေအတွက် သောကဖြစ်တတ်သည်။ အလုပ်နေရာပြောင်းရွှေ့ရလိမ့်မည်။ မမျှော်လင့်သော အမှုကိစ္စတစ်ခုနှင့် ရင်ဆိုင် ရလိမ့်မည်။ ရွှေထည် ပစ္စည်း တစ်ခုဝင်မည်။ လက်တစ်ဘက်နာရန်ရှိသည်။ ငယ်ရွယ်သူဖြစ်ပါက အိမ်ထောင်ကျလိမ့်မည်။ လုပ်ငန်း အသစ်ပေါ်လိမ့်မည်။ အိမ်နေရာ ပြောင်း သို့မဟုတ် အိမ်ပြင်ရမည်။" },
       { name: "The High Priestess",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/02.JPG", description: "အိမ်ထောင်ရေး အဆင်မပြေ ဖြစ်မည်။ သားသမီးတစ်ဦး၏ အောင်မြင်သောသတင်းကို ကြားရမည်။ ထို့အတူ မကျန်းမာသောသတင်းပါ ပါလာမည်။  မကြာခဏဆိုသလို ခရီးထွက်ရမည်။ အလုပ်အကိုင်တိုးတတ်မည်။ အလုပ်ရွှေ့ပြောင်းရမည်။ နေအိမ်ပြင်ခြင်း၊ ဆေးသုတ်ခြင်း ပြုလုပ်ရလိမ့်မည်။ ထူးခြားသောပုဂ္ဂိုလ်တစ်ဦး၏ဓာတ်ပုံ ရောက်လာမည်။ ငွေအလုံးအရင်းနှင့် ဝင်မည်။ အိမ်နားတွင် ပွဲလမ်းသဘင်ဖြစ်မည်။ ထို့သို့ဖြစ်ပါက သင့်အနေနှင့် စီးပွားတတ်လိမ့်မည်။ မျက်နှာချင်းဆိုင်အိမ် ( သို့မဟုတ် ) ခေါင်းရင်းဘက်က အိမ်၌ အလှူ ( သို့မဟုတ် ) မင်္ဂလာဆောင်ဖြစ်လိမ့်မည်။ ဤသို့ဖြစ်လျှင် သင့်အိမ် စီးပွားတတ်လိမ့်မည်။" },
       { name: "The Empress",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/03.JPG", description: "မျက်စိကို ဥပါဒ်ပေးလိမ့်မည်။ မမျှော်လင့်ဘဲ ကြွေးတင်ခံရမည်။ ငွေအတွက် ကြီးစွာ သောဒုက္ခ တွေ့မည်။ အလုပ်တွင် အခက်အခဲတွေ့မည်။ အလုပ်မှ ထွက်ရ တတ်သည်။ မမျှော်လင့်ဘဲ လူတစ်ယောက်၏ အကူအညီကို ရရှိလိမ့်မည်။ ပညာတစ်ခုရတတ်သည်။ သူခိုးခိုးခံရတတ်သည်။ လမ်းခရီး၌ ဖြစ်လိမ့်မည်။ တန်ခိုးသြဇာ မြင့်မားလာလိမ့်မည်။ စက်ရှိက ထွက်လိမ့်မည်။ ကြီးကဲသူငြိုငြင်မှုကို ခံရတတ်သည်။ အမှုမဖြစ်ရအောင်နေပါ။ ဖြစ်က ကြာမည်။ ငွေဖြင့် မလိုက်နှင့်။ လိုက်လျှင် အမှုပါရှုံးသွားလိမ့်မည်။ အစာအိမ်ရောဂါဖြစ်လိမ့်မည်။ ဂရုစိုက်ပါ။ အလွန်ခင်မင်သူတစ်ဦးနှင့် အဆင်မပြေဖြစ်က လမ်း ခွဲရတော့မည်။" },
              { name: "The Emperor",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/04.JPG", description: "သတင္းေကာင္းၾကားရမည္။ စာခ်ဳပ္စာတမ္း၌ လက္မွတ္ထိုးရမည္။ အစိုးရ၏ ခ်ီးျမွင့္မ, စမႈ ရမည္။ မသြားေတ့ာဘူးလိုလိုႏွင့္ ခရီးသြားျဖစ္လိမ့္မည္။ ပညာရပ္တစ္ခု ရရိွမည္။ စာေမးပြဲေအာင္မည္။ အလုပ္တစ္္ခုမွတစ္ခုသို႔ အေျပာင္းအလဲျဖစ္မည္။ ေငြအလြန္ကုန္မည္။ အိမ္တြင္းေရးအဆင္မေျပျဖစ္မည္။ ဓာတ္ပုံမ်ားေရာက္ရိွလာမည္။ လမ္းထဲမွာလူတစ္ဦး၏ အကူအညီကို ရရိွလိမ့္မည္။ သင္သည္ အထက္ဂိုဏ္းတစ္ခုခုကို ဝင္ျပီးမွ ဂိုဏ္းပ်က္ သြားတတ္သည္။ အေမြရလိမ့္မည္။ မယူပါႏွင့္။ ယခုယူလွ်င္ ကုန္သြား လိမ့္မည္။" },
               { name: "The Hierophant",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/05.JPG", description: "အိမ်ထောင်ရေး အဆင်မပြေ ဖြစ်မည်။ သားသမီးတစ်ဦး၏ အောင်မြင်သောသတင်းကို ကြားရမည်။ ထို့အတူ မကျန်းမာသောသတင်းပါ ပါလာမည်။  မကြာခဏဆိုသလို ခရီးထွက်ရမည်။ အလုပ်အကိုင်တိုးတတ်မည်။ အလုပ်ရွှေ့ပြောင်းရမည်။ နေအိမ်ပြင်ခြင်း၊ ဆေးသုတ်ခြင်း ပြုလုပ်ရလိမ့်မည်။ ထူးခြားသောပုဂ္ဂိုလ်တစ်ဦး၏ဓာတ်ပုံ ရောက်လာမည်။ ငွေအလုံးအရင်းနှင့် ဝင်မည်။ အိမ်နားတွင် ပွဲလမ်းသဘင်ဖြစ်မည်။ ထို့သို့ဖြစ်ပါက သင့်အနေနှင့် စီးပွားတတ်လိမ့်မည်။" },
               { name: "The Lover",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/06.JPG", description: "မင်္ဂလာရက်ရွေ့ ရမည်။ အချစ်ရေးတွင် ကြားလူ အနှောင့်အယှက်ပေါ်မည်။ မြေ၊ အိမ်ရ တတ်သည်။ စက်တစ်ခုဝင်လာမည်။ အလွန်ကောင်းသည်။ ပညာဖြင့် လာဘ်ရမည်။ ပညာထူးရရှိမည်။ ရေကြောင်းခရီးသွားရမည်။ ( ပြည်ပခရီးဖြစ်နိုင်သည် ) အမှုဖြစ်လိမ့်မည်။ အလုပ်၌ စိတ်မဝင်စားသကဲ့သို့ ရှိမည်။ အလုပ်ပြောင်းရမည်။  ငွေရေးကြေးရေး အဆင်မပြေ ဖြစ်လိမ့်မည်။ မိဘ ကိုကောင်းစွာလုပ်ကျွေး ရလိမ့်မည်။ အိမ်တွင် နားလေးသူရှိမည်။ မရှိပါက မိမိကိုယ်တိုင် နားကို ဥပဒ်ပေးတတ်သည်။" },
               { name: "The Chariot",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/07.JPG", description: "စပ်တူရှယ်ယာ လုပ်ငန်းတစ်ခု လုပ်ရမည်။ မိမိထက် အသက်အားဖြင့်ကြီးသူ၊ ဂုဏ်အားဖြင့်ကြီးသူ တစ်ဦးဦးက ကူညီလိမ့်မည်။ ခြေထောက်တစ်ဖက် နာလိမ့်မည်။ သူတစ်ပါးအတွက် သက်သေ လိုက်ရမည်။ အိုးသစ်အိမ်သစ် ရတတ်သည်။ မိန်းမဧည့်သည် တစ်ဦး အိမ်သို့ရောက်လာမည်။ ကောင်းသည်။ အောက်လက်ငယ်သားများ မင်္ဂလာပြုတတ်သည်။ ခန္ဓာကိုယ်တစ်ခြမ်း အောင့်သကဲ့သို့ ရောဂါရတတ်သည်။ စိုးရိမ်စရာမလိုပါ။ အလိုလိုပျောက်ကင်းမည်။ နောက်သုံးလကြာလျှင် စီးပွားတတ်မည်။" },
                  { name: "The Strength",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/08.JPG", description: "ဆိုင်ခန်းတစ်ခု ရရှိမည်။ သူတစ်ပါးကို အာမခံမိ၍ စိတ်ညစ်ရတတ်သည်။ သူတစ်ပါးအမှု လိုက်လံဆောင်ရွက် ရတတ်သည်။ မိမိ၌လည်း အမှုပေါ်တတ်သည်။ ကိုးကွယ်မှုဆိုင်ရပစ္စည်းများ၊ အထူးသဖြင့် ဘုရားပုံ တော်များ ရောက်လာမည်။ အစားမှားခြင်းဖြစ်လိမ့်မည်။ ဆင်ခြင်ပါ။သူတစ်ပါးက မိမိအား မတရားစွပ်စွဲ ပြောဆိုခြင်း ခံရမည်။သည်းခံပါ။ ငွေဝင်လိမ့်မည်။ ဆက်တိုက်ဝင် လိမ့်မည်။လုပ်ငန်း၌ ရန်ပေါ်တတ်သည်။အမွေအတွက် စကားပြောရတတ်သည်။ မြေနေရာကို အကြောင်းပြု၍လည်း စကားပြောရမည်။ ခြေလက်ခိုက်မိ၍ သွေးထွက်သံယို ဖြစ်တတ်သည်။လုပ်သမျှ ကိုင်သမျှ အလွဲလွဲ အချော်ချော် ဖြစ်လိမ့်မည်။သူတစ်ပါးက မိမိအား မကောင်းသော ပယောဂအတတ်ဖြင့် ပြုစားထားသကဲ့သို့ စိတ်၌ခံစားရလိမ့်မည်။" },
                   { name: "The Hermit",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/09.JPG", description: "နိုင်ငံခြားခရီး၊ ရပ်ဝေးခရီး သွားရမည်။ လက်ဆောင်ပစ္စည်းများ ရောက်လာမည်။ အမိအဖ မျက်စိရောဂါ ကုပေးရလိမ့်မည်။  မိမိကိုယ်တိုင်လည်း မျက်စိအားနည်း၍ မျက်မှန်တပ်ရမည်။ ဘာသာရေးကို ဖိဖိစီးစီးလုပ်ပါ။ အကျိုးထူးတော့မည်။  ပညာထူးရတတ်သည်။ ဆရာသမား၊ အကြီးအကဲ မ, စလိမ့်မည်။ အရေးမကြီးသော အကြောင်းအရာကြောင့် စကားများတတ်သည်။ စက်အသစ်တစ်ခု ဝင်လိမ့်မည်။ အိမ်တွင်ဆွဲထားသော နတ်အုန်းသီး အစို့ပေါက်လိမ့်မည်။ ငွေရှာ၍ လွယ်သောအချိန်ဖြစ်သည်။ ယခုလက်ရှိလုပ်နေသော အလုပ်၌ မပျော်ပိုက်ဘဲရှိလိမ့်မည်။ အမြန်ဆုံးထွက်လိုက်ပါ။ " },
                   { name: "Wheel of Fortune",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/10.JPG", description: "အပြောင်းအရွှေ့ဖြစ်မည်။ အိမ်ပြောင်း၊ အလုပ်ပြောင်းများ ပေါ်မည်။ ရေပိုက်များပြင်ရမည်။ ရေနှင့်ပတ်သက်၍ စိတ်ညစ်ရတတ်သည်။ အိမ်ထောင်ဖက် ဖျားနာမည်။ ကလေးရှိ က အမြင့်မှ လိမ့်ကျတတ်သည်။ ပညာရေး( သို့မဟုတ် ) မိန်းမတစ်ယောက်အတွက် သောက ရောက်ရတတ်သည်။ ထူးခြားသောဓာတ်ပုံတစ်ခု ဝင်လာမည်။ ဝင်ပြီးက လူနှစ်ယောက် အိမ်တွင် လာရောက်တည်းခိုမည်။ ကောင်း၏။ ဆေးရုံ တတ်၍ရောဂါ တစ်ခု ကုသရတတ်သည်။ သူတစ်ပါးကို ငွေစိုက်၍ လျော်ရမည်။ ငွေအတွက် စိတ်ပူပန်ရမည်။ အိုးသစ်အိမ်သစ်ဆောက်ရမည်။ အိမ်ပြင်ရမည်။ ခရီးမထွက်ချင်ဘဲ ခရီးထွက်ရမည်။ မျက်စိ၌ ဥပဒ်ရောက်မည်။ သူတစ်ပါးကိစ္စ မိမိဝင်ပါရမည်။ ရာထူးတက်ခြင်း၊ လာဘ်ရွှင်ခြင်း ဖြစ်မည်။" },
                    { name: "Justic",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/11.JPG", description: "အလုပ်ငန်းကြီးများပေါ်မည်။ တစ်စုံတစ်ယောက်နှင့် တရားဆိုင်ရတတ်သည်။ ရန်ပွဲတစ်ပွဲ ကြုံရမည်။ အခက်အခဲများရှိသော်လည်း အားလုံးပြေပျောက်သွားမည်။ မယ်တော်မနုစာရီက မ,စလိမ့်မည်။ လူချီးမွမ်းခံရမည်။ ထီပေါက်မည်။ ကျိန်းသေသလောက်ရှိသည်။ ထီးထိုးဖြစ်အောင်ထိုးပါ။ နေရာပြောင်းရွှေ့ရတတ်သည်။ ခရီးဝေးသွားရမည်။ မိတ်ဆွေရင်းတစ်ဦး မိမိကို မကောင်းကြံတတ်သည်။ ဂရုစိုက်ပါ။ လူလိမ်ခံရကိန်းရှိသည်။ အိမ်တွင်းရေး၌လည်း အဆင်မပြေ ဖြစ်တတ်ပါသည်။ အစိုးရ၏ ချီးမြှောက်ခြင်းခံရမည်။ မသွားတော့ဘူးလိုလိုနှင့် ခရီးတစ်ခုသွားရမည်။ ငွေအလုံးအရင်းနှင့် ဝင်တတ်သည်။ အလှူအတန်းတစ်ခု မုချ လုပ်ရမည်။" },
                    { name: "The Hanged Man",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/12.JPG", description: "လုပ်သမျှ ကိစ္စအားလုံး တိုးလိုးတန်းလန်းဖြစ်နေမည်။ ငွေများဝင်သော်လည်း အဖတ်မတင် ဖြစ်မည်။နေအိမ်အနီး၌ နာရေးပေါ်မည်။ သွားရောက်ခြင်းမပြုပါနှင့်။ အငြိမ်နေရသည်မရှိဘဲ လှုပ်ရှားနေရမည်။ ကျန်းမာရေး တစ်ခုပြီးတစ်ခု ညံ့မည်။ သွေးထွက်သံယို ဖြစ်မည်။ မြေအတွက်၊ အမွေအတွက် စကား အကြီးအကျယ်ပြောရမည်။ ငွေများ တရစပ်ဝင်မည်။ ဝင်သော် ဗုဒ္ဓဟူးနံ ကိုအကြောင်းပြုဝင်မည်။ သူတစ်ပါး၏ အကြောင်းမဲ့ စွပ်စွဲပြော ဆိုခြင်းခံရမည်။ အလှူတစ်ခုလုပ်ရမည်။ မကြာမီ၌ စီးပွားသစ်၊ လုပ်ငန်းသစ် ဖြစ်ဖွယ်ရာရှိသည်။ ဟိုဟာလုပ်ရကောင်းနိုး၊ ဒီဟာလုပ်ရကောင်းနိုးနှင့် အချိန် ကုန်သွားတတ်သည်။ ပစ္စည်းများ ထုခွဲရောင်းချ၍ လုပ်ငန်းသစ်လုပ်လို စိတ်ပေါ်မည်။" },
                    { name: "Death",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/13.JPG", description: "လုပ်ငန်းအကြံအစည်အသစ်ပေါ်မည်။ မြေဝင်မည်။ ရင်းနှီးသောသူ ဆေးရုံတတ်ရမည်။ သွား၍မေးရမည်။ ဆွေမျိုးသားချင်းတစ်ဦး ရပ်ဝေး၌ ဆုံးပါးသော သတင်းကိုကြားရမည်။ အမှုအခင်းဖြစ်ပွား၍ အချုပ်အနှောင် ခံရမည်။အလုပ်၌ စိတ်မဝင်စားသကဲ့သို့ ဖြစ်မည်။ အလုပ်ထွက်ချင်စိတ် ပေါ်မည်။ငွေရှာဖွေ၍ လွယ်မည်။ ငွေများလည်းဝင်မည်။ စက်တစ်လုံးဝင်မည်။ ထိုစက်ဝင်ပြီးနောက် ထီပင်ပေါက် တတ်သည်။ တစ်စုံတစ်ယောက်နှင့် စကားများလိမ့်မည်။စကားများ သောသူ မိမိထက်ကြီးလိမ့်မည်။ပညာထူးရမည်။" },
                     { name: "Temperance",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/14.JPG", description: "အလုပ်နေရာ၊ အိမ်နေရာ ပြောင်းရွှေ့ ရမည်။ အိမ်ပြင်၊ အိမ်ဆောက် ပြုလုပ်ရမည်။ ရေလမ်းခရီးမှ ဧည့်သည်လာမည်။ ကားသစ်ဝင်မည်။ အလုပ်နေရာ၊ အိမ်နေရာ အရွှေ့ အပြောင်းဖြစ်သည်။ မကြာခဏ ခရီးထွက်ရမည်။  ရုတ်တရက် ရာထူးတိုးခြင်း၊ စီးပွားတိုးတက်ခြင်း ဖြစ်မည်။ မိတ်ဆွေတစ်ယောက် အတွက် စိတ်သောက ရောက်ရ တတ်သည်။ သမီးရှိသော် အိမ်မှထွက်သွား တတ်သည်။ အိမ်တစ်လုံးထဲတွက် မိသားစုနှစ်စု ပူးပေါင်းနေထိုင်ရမည်။" },
                       { name: "The Devil",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/15.JPG", description: "အချစ်ရေး၊ အိမ်ထောင်ရေး ပြဿနာ ပေါ်တတ်သည်။ လေထိုးလေအောင့်ဖြစ်မည်။ စကားများကိန်းရှိသည်။ ယခုနေသောနေရာ၌ မြေမသန့်ပါ။ အိမ်နှင့်မလှမ်းမကမ်း နေရာ၌ တစ္ဆေ သရဲရှိသည်။ ထူးခြားသော ရုပ်ထုတစ်ခု ရောက်လာတတ်သည်။ ရောက်လာ ပြီးနောက်စနေသားတစ်ဦးထံမှ လာဘ်လာဘ ရမည်။ မမျှော်လင့်ဘဲ မိတ်ဆွေကောင်းတစ်ဦး ရရှိလိမ့်မည်။ ထို့အတူ ဘဝတစ်ခုလုံးအတွက် အသုံးဝင်သော ပညာလည်းရမည်။ အောက်လက်ငယ်သား ဒုက္ခပေး တတ်သည်။မျက်စိနာမည်။ ပစ္စည်းပျောက်မည်။ " },
                        { name: "Tower",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/16.JPG", description: "သွားမည့်ခရီးသည် တစ်ခါတည်းနှင့် အထမမြောက်နိုင်ပါ။ အကြံအစည်ပျက်ပြားမှု၊ လုပ်ငန်းဆုံးရုံးမှု များပေါ်မည်။ သွပ်၊ သံ၊ သစ်ကိစ္စကြောင့် စိတ်ညစ်ရတတ်သည်။ အပျက်အစီး အဆုံးအရုံးများမည်။ ဆရာတု ဆရာယောင်နှင့် တွေ့တတ်သည်။ ထိုသူ၏အမည်မှာ တနင်္လာစာလုံးပါမည်။ ငွေသည် ကြံဖန်၍ ကုန်စရာပေါ်ပြီး ကုန်မည်။ ရုတ်တရက် ခါးမျက်၊ ခါးနာပေါ်မည်။မိတ်ဆွေတစ်ဦး မိမိအပေါ်၌ အထင်လွဲလိမ့်မည်။ မုဆိုဖို၊ မုဆိုးမတစ်ဦးဦး အိမ်သို့ လာရောက်တည်းခိုမည်။" },
                         { name: "The Star",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/17.JPG", description: "နာမည်ကြီးထမင်းငတ်ဆိုသကဲ့သို့ ဖြစ်မည်။ အိမ်၌ လျှပ်စစ်ရှော့ဖြစ် လိမ့်မည်။ကြွေးကိစ္စ စကား အချေအတင်ပြောရတတ်သည်။ ကျား-သွားရောဂါဒုက္ခပေးမည်။  မ-သွေးရောဂါ ဒုက္ခပေးမည်။ လူပွေလူရှုပ်များ အိမ်သို့ရောက်လာမည်။ ရောက်လျှင်နှစ်ကြိမ်ရောက်မည်။ မိတ်ဆွေကောင်းနှစ်ဦး တစ်ပြိုင်တည်း ရမည်။ထူးဆန်းသော အသိပညာထူး တစ်ခုရမည်။စိတ်ညစ်စရာ နှစ်ကြိမ် ပေါ်မည်။ခရီးမထွက်ချင်ဘဲ နှစ်ကြိမ်ထွက်ရမည်။ ငွေများဝင်လျက် ကြွေးတင်နေမည်။ ကြီးကြီးမားမား ပေးဆပ်ရန် ကြွေးနှစ်ခု ရှိမည်။ နာမည်အလွန်ကြီးသွားမည်။ ကောင်းသောကြီးခြင်းဖြစ်မည်။ မိမိလုပ်သောအလုပ်တွင် နှစ်ကြိမ်ဆုံးရုံးမှုဖြစ်မည်။ တစ်အိမ်သားလုံးကျန်းမာရေး ညံ့မည်။ဆေးရုံ၊ ဆေးခန်း တတ်ရောက်ကုသရခြင်းအထိ ကျန်းမာရေးညံ့မည်။" },
                           { name: "The Moon",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/18.JPG", description: "အိမ်ကိစ္စ ပြဿနာပေါ်မည်။ သားသမီးတစ်ဦးဦး အတွက် စိတ်ညစ်ရမည်။ လောင်းကစား လုပ်သူဆိုပါက ဒုက္ခရောက်တော့ မည်။ ရပ်ထားပါ။ ခရီးဝေးသွား ရလိမ့်မည်။  မိမိနေသော အရပ်တွင် တွေ့နေကြဆိုင်းဘုတ်တစ်ခု ဖြုတ်ချလိုက်၍ မတွေ့တော့ဘဲရှိမည်။ ဤသို့ဖြစ်လျှင် မုဆိုးမတစ်ဦးကို မိမိထိုးလိုသော ဂဏန်းပြော၍ ထီထိုးခိုင်းပါ။ ထီပေါက်မည်။ ဘုရားရုပ်တုတော်၊ ဆင်းတုတော် ကြွလာမည်။ ငွေများဝင်သော်လည်း ထွက်စရာ ဆက်တိုက်ပေါ်မည်။ ခြေထောက်နာမည်။ အိုးသစ်အိမ်သစ်ပြောင်းရကိန်းပေါ်မည်။ ( သို့ ) အိမ်ပြင်ရမည်။ မီးဖိုဘက်တွင် မီးလန့်ခြင်း၊ တစ်စုံတစ်ယောက် ကိုအပူ လောင်ခြင်းဖြစ်မည်။ ဤသို့ဖြစ်သော် မိမိ၏ အဝတ်ဟောင်းတစ်ထည်ကို စွန့်ပစ်ပြီးထီထိုးပါ။ ၁၀ ပုံ ၁ ပုံ လှူပါမည်ဟု အဓိဌာန်ပြုပါ။" },
                            { name: "The Sun",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/19.JPG", description: "ပညာထူးချွန်မည်။ စပ်တူလုပ်ငန်းလုပ်ရမည်။ အောင်မြင်မည်။ အချစ်ရေး ကံကောင်းသည်။ အိမ်ထောင်းရေး ပို၍ကံကောင်းပါသည်။ အောင်မြင်မည့် ကာလဖြစ်သည်။ ဘာပဲလုပ်လုပ် ရဲရဲလုပ်ပါ။ ရပ်ဝေးခရီး သွားရတတ်သည်။ မမျှော်လင့်သော်သူထံမှ အကူအညီ ရမည်။ စွန့်စားရသော လုပ်ငန်းများ လုပ်ရတတ်သည်။ ၇ နံပါတ် အကျိုးမပေးပါ။" },
                             { name: "Judgement",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/20.JPG", description: "ရပ်ဝေးရောက်နေသော အိမ်သားတစ်ဦး ပြန်ရောက်လာလိမ့်မည်။ မိမိအထက်အရာရှိ တစ်ဦးပြောင်းရ တော့မည်။ ရက်ပိုင်းသာလို တေ့ာသည်။ ယခုမှ နှစ်လကျော်လျှင် စီးပွားအလွန်တက်မည်။ တစ်စုံတစ်ဦး ကလေးမွေးဖွားသော သတင်းကြားရလျှင်လည်း စီးပွားတတ်တတ်ပါသည်။ ဆွေမျိုးထဲမှ အမျိုးသမီးကြီးတစ်ဦး၏ အကူအညီ ကိုရမည်။ ရွှေဝင်မည်။ လက်တွဲရန် အဖော်ကောင်းရမည်။ ရပ်ဆိုင်းထားသောလုပ်ငန်းများ ပြန်လည်ရှင်သန်လာမည်။ နာမည်ဂုဏ်သတင်းများ နေ့ချင်းညချင်း တိုးတတ်လာမည်။ မျှော်လင့်ထားသော အကြောင်းအရာ အားလုံး လက်ဝယ်ပိုင်ပိုင် ရရှိမည်။ ထူးခြားသော စာအုပ်တစ်အုပ် ဖတ်ရမည်။ ထိုစာအုပ်မှ အကြံသစ်ဉာဏ်သစ်ရရှိမည်။ ဉာဏ်မကွန့်ပါနှင့်။ ရိုးရိုးသားသားလုပ်ပါ။ မုချအောင်မြင်မည်။" },
                              { name: "The World",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/21.JPG", description: "နိင်ငံရပ်ခြားနှင့် ဆိုင်သောကိစ္စ များပေါ်မည်။ အောင်မြင်မည်။ ကိုးကွယ်သောနတ်များ မ,တော်မူမည်။ သိုလှောင်ပစ္စည်းမြတ်မည်။ အစွပ်စွဲ ခံရကိန်း ရှိသည်။ သတိပြုပါ။ မင်းစိုးရာဇာ ဂုဏ်ပြုလိမ့်မည်။ သိကြားမ, သကဲ့သို့ ရုတ်တရက် ကြီးပွား ချမ်းသာသွားမည်။ ရှေ့ရေးအတွက် ဘာမှမပူပါနှင့် အားလုံးကောင်းပြီဟု နားလည်ပါ။ယခုနေသော နေရာမှ လည်း ပြောင်းရွှေ့ ရကိန်းရှိသည်။ ပို၍ ကောင်းသောနေရာ သို့ ရောက်မည်။ပူပင်သောက အားလုံးကို မေ့ပစ်လိုက်ပါ့တော့။ စိတ်ချမ်းသာမှုများ၊ တိုးတတ်မှုများ တစ်ခုပြီးတစ်ခုရှိမည်။ ကြံစည်ထားသော အကြံအစည်များ ဆုတ်ဆိုင်းခြင်းမရှိ အားလုံးအဆင်ပြေသွားမည်။ ထီပေါက်ကိန်းရှိသည်။ ပေါက်သော်သတိထားပါ။ အိမ်သူအိမ်သား တစ်ဦးဦး ရုတ်တရက် နာမကျန်းကြီးစွာ ဖြစ်တတ်သည်။" },

    ],
  },
  {
    question: "ချစ်ရေးဆိုရင် လက်ခံမှာလား",
    cards: [
       { name: "The Fool",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/00.JPG", description: "ခံနိုင်ရည်ရှိအောင် ကြိုးစားပြီးမှ ချစ်ရေးဆိုပါ" },
       { name: "The Magician",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/01.JPG", description: "လက်ခံပါမယ် ဒါပေမယ့် ဟန်ဆောင်မှုတွေနဲ့ပါ" },
       { name: "The High Priestess",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/02.JPG", description: "နှစ်ခါ ကြိုးစားပြီးမှ အောင်မြင်မှာပါ" },
       { name: "The Empress",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/03.JPG", description: "အချစ်စစ်မှန်ရင် ခေါင်းညိတ်မှာပါ" },
              { name: "The Emperor",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/04.JPG", description: "နည်းနည်းလေး ပိုကြိုးစားရင်တော့ ခေါင်းညိတ်မှာပါ" },
               { name: "The Hierophant",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/05.JPG", description: "အချစ်စစ်မှန်ရင် ခေါင်းညိတ်မှာပါ" },
               { name: "The Lover",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/06.JPG", description: "သင့်ကို ငြင်းမှာ မဟုတ်ဘူးဆိုတာ သင်လဲ သိပြီးသားပါ" },
               { name: "The Chariot",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/07.JPG", description: "နောက်တစ်ယောက်ကိုလဲ သူရွေးခြယ်ဖို့ စဉ်းစားနေပါတယ်။" },
                  { name: "The Strength",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/08.JPG", description: "စဉ်းစားဖို့ အချိန်နည်းနည်း ပေးလိုက်ပါ" },
                   { name: "The Hermit",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/09.JPG", description: "ကိုယ်တိုင်ကရော ရိုးသားလို့လား" },
                   { name: "Wheel of Fortune",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/10.JPG", description: "ကိုယ်က အချစ်စစ်နဲ့ ချစ်တာဆိုရင်တော့ ခေါင်းညိတ်မှာပါ" },
                    { name: "Justic",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/11.JPG", description: "သင်ကိုယ်တိုင် အချစ်စစ်မှန်မှ ဖွင့်ပြောလိုက်ပါ" },
                    { name: "The Hanged Man",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/12.JPG", description: "ဖွင့်ပြောဖို့ မရဲသေးရင် မပြောတာ ပိုကောင်းပါတယ်" },
                    { name: "Death",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/13.JPG", description: "ငြင်းမှာပါ" },
                     { name: "Temperance",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/14.JPG", description: "မိသားစုနဲ့ အဆင်ပြေမယ့်လူဆိုရင်တော့ ခေါင်းညိတ်မှာပါ" },
                       { name: "The Devil",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/15.JPG", description: "သင့်ကို လှည့်စားမယ့်သူပါ" },
                        { name: "Tower",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/16.JPG", description: "ဖွင့်မပြောမိအောင် ကြိုးစားရင် ပိုကောင်းပါတယ်" },
                         { name: "The Star",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/17.JPG", description: "လှည့်စားခံရနိုင်ပါတယ်" },
                           { name: "The Moon",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/18.JPG", description: "နောက်တစ်ခေါက် ပြန်ကြိုးစားဖို့ အခွင့်အရေး ပေးပါလိမ့်မယ်" },
                            { name: "The Sun",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/19.JPG", description: "ခေါင်းညိတ်မှာပါ" },
                             { name: "Judgement",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/20.JPG", description: "အောင်သွယ်ကောင်းရင်တော့ အဆင်ပြေမှာပါ" },
                              { name: "The World",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/21.JPG", description: "ခေါင်းညိတ်မှာပါ" },

    ],
  },
  {
    question: "သူက ဘယ်လိုလူမျိုးလဲ",
    cards: [
     { name: "The Fool",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/00.JPG", description: "ပျော်ပျော်နေတတ်ပြီး ခင်တတ်သူပါ" },
       { name: "The Magician",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/01.JPG", description: "ဟန်ဆောင် ကောင်းသူပါ" },
       { name: "The High Priestess",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/02.JPG", description: "တည်ငြိမ်သူပါ" },
       { name: "The Empress",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/03.JPG", description: "သင်နားလည်ရခက်မယ့် လူမျိုးပါ" },
              { name: "The Emperor",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/04.JPG", description: "သင်ကြောက်ရမယ့် လူမျိုးပါ" },
               { name: "The Hierophant",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/05.JPG", description: "တည်ငြိမ်ပြီး ရိုးသားပါတယ်" },
               { name: "The Lover",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/06.JPG", description: "သင့်ကို တော်တော်လေး ချစ်မယ့်လူမျိုးပါ" },
               { name: "The Chariot",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/07.JPG", description: "စိတ်နှစ်ခွနဲ့ မပြတ်သားတတ်သူပါ" },
                  { name: "The Strength",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/08.JPG", description: "ရဲရင့်ပြီး သင့်တွက်ဆို ခွန်အားပေးနိုင်သူပါ" },
                   { name: "The Hermit",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/09.JPG", description: "သင့်ကို ဦးဆောင်နိုင်မယ့် လူမျိုးပါ" },
                   { name: "Wheel of Fortune",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/10.JPG", description: "လည်လည် ဝယ်ဝယ် ရှိမယ့် လူမျိုးပါ" },
                    { name: "Justic",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/11.JPG", description: "မဟုတ်မခံ လူမျိုးပါ" },
                    { name: "The Hanged Man",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/12.JPG", description: "အမှားတွေများပြီး ပြာယာခတ်နေတတ်သူပါ" },
                    { name: "Death",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/13.JPG", description: "ကြောက်ဖို့ကောင်းအောင် စိတ်ကြီးတဲ့သူပါ" },
                     { name: "Temperance",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/14.JPG", description: "မိသားစုကို အလေးထားတတ်သူပါ" },
                       { name: "The Devil",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/15.JPG", description: "သင့်ကို လှည့်စားမယ့်သူပါ" },
                        { name: "Tower",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/16.JPG", description: "ကောင်းကွက် တစ်ခုမှကို မရှိတဲ့ လူမျိုးပါ" },
                         { name: "The Star",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/17.JPG", description: "အောင်မြင်မယ့် လူမျိုးပါ" },
                           { name: "The Moon",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/18.JPG", description: "ဖြူစဉ်ပြီး ရိုးသားပါတယ်" },
                            { name: "The Sun",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/19.JPG", description: "နေမင်းကြီးလို ထွန်းလင်းပေးမယ့် လူမျိုးပါ" },
                             { name: "Judgement",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/20.JPG", description: "မှန်လို လူမျိုးပါ" },
                              { name: "The World",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/21.JPG", description: "အားလုံးကို အကောင်းဆုံး ဖြစ်အောင် ကြိုးစားတတ်သူပါ" },      
    ],
  },
  {
    question: "သစ္စာရှိမှာလား",
    cards: [
       { name: "The Fool",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/00.JPG", description: "သင့်အပေါ်မူတည်ပါတယ်" },
       { name: "The Magician",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/01.JPG", description: "သင့်ကို တကယ်ချစ်ပေမယ့် သစ္စာတော့ မရှိပါဘူး" },
       { name: "The High Priestess",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/02.JPG", description: "အရမ်းစိတ် မပူပါနဲ့ သစ္စာရှိမဲ့ ချစ်သူမျိုးပါ" },
       { name: "The Empress",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/03.JPG", description: "သစ္စာရှိပါတယ်" },
              { name: "The Emperor",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/04.JPG", description: "သစ္စာရှိသူပါ" },
               { name: "The Hierophant",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/05.JPG", description: "သစ္စာရှိသူပါ" },
               { name: "The Lover",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/06.JPG", description: "သစ္စာရှိသူပါ" },
               { name: "The Chariot",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/07.JPG", description: "နောက်တစ်ယောက် ရှိနေပါပြီ" },
                  { name: "The Strength",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/08.JPG", description: "သစ္စာရှိသူပါ" },
                   { name: "The Hermit",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/09.JPG", description: "သစ္စာရှိသူပါ" },
                   { name: "Wheel of Fortune",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/10.JPG", description: "နည်းနည်းတော့ ပွေပေမယ့် သစ္စာတော့ ရှိနိုင်ပါတယ်" },
                    { name: "Justic",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/11.JPG", description: "သစ္စာရှိသူပါ" },
                    { name: "The Hanged Man",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/12.JPG", description: "နည်းနည်းတော့ ပွေပါတယ်" },
                    { name: "Death",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/13.JPG", description: "သစ္စာမရှိပါဘူး" },
                     { name: "Temperance",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/14.JPG", description: "သစ္စာရှိသူပါ" },
                       { name: "The Devil",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/15.JPG", description: "သင့်ကို လှည့်စားမယ့်သူပါ" },
                        { name: "Tower",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/16.JPG", description: "သစ္စာမရှိပါဘူး" },
                         { name: "The Star",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/17.JPG", description: "သစ္စာရှိသူပါ" },
                           { name: "The Moon",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/18.JPG", description: "သစ္စာရှိသူပါ" },
                            { name: "The Sun",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/19.JPG", description: "သစ္စာရှိသူပါ" },
                             { name: "Judgement",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/20.JPG", description: "သင်သစ္စာရှိရင် ပြန်ရှိမှာပါ" },
                              { name: "The World",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/21.JPG", description: "သစ္စာရှိသူပါ" }, 
      
    ],
  },
  
  
   {
    question: "ဝေဒနာ သက်သာမှာလား",
    cards: [
     { name: "The Fool",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/00.JPG", description: "အနေအထိုင် ဆင်ခြင်ပါ။ သက်သာတယ် ဆိုပြီး မပေါ့ပါနဲ့" },
       { name: "The Magician",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/01.JPG", description: "ယူပြစ်သလို ပျောက်သွားပါမယ်" },
       { name: "The High Priestess",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/02.JPG", description: "ပျောက်ကင်း သွားမှာပါ။ ဆရာကောင်း သမားကောင်း တွေ့ပါလိမ့်မယ်" },
       { name: "The Empress",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/03.JPG", description: "ပျောက်ကင်းမှာပါ" },
              { name: "The Emperor",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/04.JPG", description: "ကောင်းသွားမှာပါ" },
               { name: "The Hierophant",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/05.JPG", description: "ကောင်းသွားမှာပါ" },
               { name: "The Lover",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/06.JPG", description: "ကောင်းသွားမှာပါ" },
               { name: "The Chariot",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/07.JPG", description: "၂ ခါ ၃ ခါ လောက်ကုပြီးမှ ပျောက်ကင်း နိုင်ပါတယ်" },
                  { name: "The Strength",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/08.JPG", description: "ကောင်းသွားမှာပါ" },
                   { name: "The Hermit",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/09.JPG", description: "သေခြာဂရုစိုက်ရင် ပျောက်သွားမှာပါ" },
                   { name: "Wheel of Fortune",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/10.JPG", description: "ခဏ သက်သာသွားပြီး ပြန်ဖြစ်နိုင်ပါတယ်" },
                    { name: "Justic",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/11.JPG", description: "သက်သာပါမယ်" },
                    { name: "The Hanged Man",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/12.JPG", description: "ဂရုစိုက်ပါ မပေါ့ပါနဲ့" },
                    { name: "Death",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/13.JPG", description: "မပေါ့ပါနဲ့ ကုရခက်နိုင်ပါတယ်" },
                     { name: "Temperance",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/14.JPG", description: "အနေ အထိုင် သတိထားလိုက်ရင် သက်သာ သွားပါမယ်" },
                       { name: "The Devil",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/15.JPG", description: "မပေါ့ပါနဲ့" },
                        { name: "Tower",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/16.JPG", description: "မပျောက်နိုင်ပါဘူးလို့ပဲ သက်သက် ညှာညှာ ပြောပါရစေ" },
                         { name: "The Star",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/17.JPG", description: "ကောင်းသွားပါမယ်" },
                           { name: "The Moon",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/18.JPG", description: "ကောင်းသွားပါမယ်" },
                            { name: "The Sun",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/19.JPG", description: "ပျောက်ကင်းသွားမှာပါ" },
                             { name: "Judgement",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/20.JPG", description: "အလှု အတန်းများများ လုပ်ပေးပါ။ သက်သာလာပါမယ်" },
                              { name: "The World",  img: "https://www.pixelliongroup.com/feeds/app/mtarot/21.JPG", description: "ကောင်းသွားမှာပါ" },   
    ],
  },
  
];
