 import React, { useState, useEffect } from "react";
 
 const Home = () => { 
     return (
 <div className="lg:col-span-6 col-span-12">
                <div className="px-12 lg:px-40 mt-20">
                  <div className="rounded-full w-fit text-xs px-3 py-2 text-center bg-slate-600 text-white">
                    <i className="bx bxs-party bx-fw"></i>Alpha v.1.0.0.1
                  </div>
                  <h1 className="text-5xl raleway-800 text-bold uppercase text-yellow-500 mt-5">
                    Welcome to feeds﻿ 👋
                  </h1>
                  <span className="text-xs text-slate-500">BY PIXELSAIM</span>

                  <div className="my-10">
                    <h1 className="text-2xl font-bold uppercase lg:text-3xl">
                      What is feeds?
                    </h1>
                    <p>
                      With Feeds, discover a dynamic and engaging space where
                      your business can thrive, network and grow.
                    </p>
                  </div>
                </div>

                <a className="" href="/login"><div className="text-center bg-slate-700 text-white rounded-lg  raleway-600 py-5 py-3 mx-12 lg:mx-40 mt-20 uppercase text-md">
                  
                    Join Now{" "}
                 
                </div> </a>
              </div>
              
             ) };

export default Home;