import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { format, formatDistanceToNowStrict, differenceInMinutes, parseISO } from 'date-fns';
import { toZonedTime, formatInTimeZone } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import CommentModal from './comp/CommentModal'; // Import the CommentModal component
import {
  BrowserRouter as Router,
  Route, 
  Routes,
  NavLink, 
  useLocation 
} from "react-router-dom";

const FeedsPage = () => {
	const [ad, setAD] = useState(null);
    const [user, setUser] = useState(null);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serverTimezone, setServerTimezone] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [currentPlaying, setCurrentPlaying] = useState(null); // State to manage current playing video
    const [mutedStates, setMutedStates] = useState({}); // State to manage mute state of each video
    const [showPlayButton, setShowPlayButton] = useState({}); // State to manage play button visibility
    const [key, setKey] = useState(0); // State to manage key for re-rendering
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false); // State for comment modal
    const [selectedPostId, setSelectedPostId] = useState(null); // State to track which post's comments are being viewed
    
    const navigate = useNavigate();
    const [linkCopiedMap, setLinkCopiedMap] = useState({});
    const [expandedPosts, setExpandedPosts] = useState({});
    const observer = useRef(); // Ref to hold the observer instance

    useEffect(() => {
        const userData = sessionStorage.getItem('user');
        if (userData) {
            const user = JSON.parse(userData);
            setUser(user);
            setLoading(false);
        } else {
            navigate('/login'); // Redirect to login if no session
        }
    }, [navigate]);

    const handleLinkCopy = (postId) => {
        const link = `https://feeds.ygn.me/post/${postId}`;
        navigator.clipboard.writeText(link).then(() => {
            setLinkCopiedMap(prevState => ({ ...prevState, [postId]: true }));
            setTimeout(() => {
                setLinkCopiedMap(prevState => ({ ...prevState, [postId]: false }));
            }, 2000); // Hide message after 2 seconds
        });
    };

    const fetchPosts = async (userId, page = 1) => {
        setIsFetching(true);
        try {
            const response = await axios.get('https://www.pixelliongroup.com/feeds/WatchService.php', {
                params: { t: Date.now(), user_id: userId, page, per_page: 5 }
            });

            const { posts, server_timezone } = response.data;

            // Fetch like counts, liked status, and comment counts for each post
            const postsWithCounts = await Promise.all(posts.map(async post => {
                const countsResponse = await axios.get('https://www.pixelliongroup.com/feeds/GetLikeCount.php', {
                    params: { post_id: post.id, user_id: userId }
                });

                const { like_count, liked, comment_count } = countsResponse.data;

                return { ...post, likeCount: like_count, liked, commentCount: comment_count };
            }));

            // Ensure no duplicates are added
            setPosts(prevPosts => {
                const postIds = new Set(prevPosts.map(post => post.id));
                const newPosts = postsWithCounts.filter(post => !postIds.has(post.id));
                return [...prevPosts, ...newPosts];
            });

            setServerTimezone(server_timezone);
            setHasMore(posts.length === 5); // If less than 5 posts were returned, we have reached the end

            // Record impressions for the posts
            await Promise.all(postsWithCounts.map(post => {
                return recordImpression(userId, post.id);
            }));

        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setIsFetching(false);
            setLoading(false);
        }
    };

    const recordImpression = async (userId, postId) => {
        try {
            await axios.post('https://www.pixelliongroup.com/feeds/update.php', {
                user_id: userId,
                post_id: postId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error('Error recording impression:', error);
        }
    };

    const handleLike = async (postId) => {
        const storedUser = JSON.parse(sessionStorage.getItem('user'));
        if (!storedUser) {
            console.error('No user found in session storage');
            return;
        }

        const post = posts.find(post => post.id === postId);
        if (!post) {
            console.error('Post not found');
            return;
        }

        const action = post.liked ? 'unlike' : 'like';

        try {
            await axios.post('https://www.pixelliongroup.com/feeds/LikePost.php', 
                { post_id: postId, user: storedUser }, 
                { withCredentials: true }
            );
            setPosts(prevPosts => prevPosts.map(post => 
                post.id === postId ? { 
                    ...post, 
                    likeCount: post.liked ? post.likeCount - 1 : post.likeCount + 1, 
                    liked: !post.liked 
                } : post
            ));
        } catch (error) {
            console.error(`Error ${action}ing post:`, error);
        }
    };

    const formatDate = (date) => {
        const postDate = toZonedTime(parseISO(date), serverTimezone);
        const now = new Date();
        const zonedNow = toZonedTime(now, serverTimezone);
        const minutesAgo = differenceInMinutes(zonedNow, postDate);

        if (minutesAgo < 60) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' minutes', 'm').replace(' minute', 'm');
        } else if (minutesAgo < 1440) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' hours', 'h').replace(' hour', 'h');
        } else {
            return formatInTimeZone(postDate, serverTimezone, 'd MMM yyyy');
        }
    };

    const loadMore = () => {
        if (!isFetching && hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        if (user) {
            fetchPosts(user.id, page);
        }
    }, [page, user]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
                loadMore();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    
    const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

const exceedsWordLimit = (text, wordLimit) => {
    return text.split(' ').length > wordLimit;
};

const toggleExpanded = (postId) => {
    setExpandedPosts(prevState => ({
        ...prevState,
        [postId]: !prevState[postId]
    }));
};

const handleIntersection = (entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            setCurrentPlaying(entry.target.getAttribute('data-id'));
        }
    });
};

useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.75 // Adjust this value to determine how much of the video should be visible before it plays
    });

    const elements = document.querySelectorAll('.video-container');
    elements.forEach(element => {
        observer.current.observe(element);
    });

    return () => {
        if (observer.current) {
            observer.current.disconnect();
        }
    };
}, [posts]);

const togglePlay = (postId) => {
    setCurrentPlaying(prevState => (prevState === postId ? null : postId));
    setShowPlayButton(prevState => ({
        ...prevState,
        [postId]: prevState[postId] ? false : true
    }));
    setKey(prevKey => prevKey + 1); // Force re-render by changing the key
};

const toggleMute = (postId) => {
    setMutedStates(prevState => ({
        ...prevState,
        [postId]: !prevState[postId]
    }));
    setKey(prevKey => prevKey + 1); // Force re-render by changing the key
};

const openCommentModal = (postId) => {
    setSelectedPostId(postId);
    setIsCommentModalOpen(true);
};
  const location = useLocation(); // Get the current location
  const isDarkTheme = location.pathname === '/watch';

    return (
        <div className="lg:col-span-6 col-span-12 h-[100dvh] relative">
            <div className="overflow-hidden bg-black h-full">
                <a href="/feedspage"><div className="text-white m-5 z-10 absolute text-lg"><i className='bx bx-chevron-left-circle bx-fw'></i></div></a>
                {loading ? (
                    <div className="animate-pulse w-full h-full flex flex-col items-center justify-center absolute top-0">
                        <div className="w-12 h-12 rounded-full bg-gray-300 mb-4"></div>
                        <div className="w-40 h-5 bg-gray-300 mb-2"></div>
                        <div className="w-60 h-3 bg-gray-300 mb-4"></div>
                        <div className="w-full h-60 bg-gray-300 mb-4"></div>
                        <div className="w-full h-20 bg-gray-300"></div>
                    </div>
                ) : (
                    <div className="w-full h-full overflow-auto snap-y snap-mandatory relative">
                        {posts.map(post => (
                            <div key={post.id} data-id={post.id} className="video-container w-full h-[100dvh] flex-shrink-0 snap-start relative">
                                {post.type === 'video' && (
                                    <div onClick={() => togglePlay(post.id)} className="relative w-full h-full">
                                        <ReactPlayer 
                                            key={key} // Force re-render when key changes
                                            className="react-player absolute top-0 left-0 w-full h-full"
                                            width="100%" 
                                            height="100%" 
                                            muted={currentPlaying !== post.id || mutedStates[post.id]}  // Mute if not the current playing video or muted state is true
                                            playing={currentPlaying === post.id}  // Play only if it is the current playing video
                                            loop={true} 
                                            controls={false} 
                                            url={JSON.parse(post.content).video} 
                                            onReady={() => console.log('Video ready')}
                                            onStart={() => console.log('Video started')}
                                            onPlay={() => setShowPlayButton(prevState => ({ ...prevState, [post.id]: false }))}
                                            onPause={() => setShowPlayButton(prevState => ({ ...prevState, [post.id]: true }))}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        playsInline: true, // Prevent fullscreen on iOS
                                                        disablePictureInPicture: true // Disable PiP mode
                                                    }
                                                }
                                            }}
                                        />
                                        {showPlayButton[post.id] && (
                                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                                <button className="text-white text-8xl">
                                                    <i className='bx bx-play'></i>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="client-info absolute bottom-0 pb-[80px] left-0 px-5 w-full bg-gradient-to-t from-black to-transparent text-white">
                                    {post.client_name ? (
                                        <div className="flex items-center mb-4">
                                            <img 
                                                src={post.client_profile_picture} 
                                                alt={post.client_name} 
                                                className="w-12 h-12 rounded-full border border-white mr-4"
                                            />
                                            <div>
                                                <p className="text-lg font-bold">{post.client_name} {post.client_verify == 1 && <i className='bx bxs-bolt-circle text-yellow-500 '></i>}</p>
                                                <p className="text-sm">@{post.client_username} · {post.client_industry} · {formatDate(post.created_at)}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-lg font-bold">Client not available</p>
                                    )}
                                    <p className="text-sm mb-5 mr-12 leading-relaxed">
                                        {expandedPosts[post.id] ? JSON.parse(post.content).text : truncateText(JSON.parse(post.content).text, 8)}
                                        {exceedsWordLimit(JSON.parse(post.content).text, 8) && (
                                            <button 
                                                onClick={() => toggleExpanded(post.id)} 
                                                className="text-slate-500"
                                            >
                                                {expandedPosts[post.id] ? ' Show Less' : ' Read More'}
                                            </button>
                                        )}
                                    </p>
                                </div>
                                <div className="action-buttons absolute text-2xl right-7 text-center bottom-[150px] flex flex-col items-center space-y-8 text-white">
                                    <button onClick={() => handleLike(post.id)} className="flex flex-col items-center">
                                        <i className={`bx bx-like bx-fw hover:text-blue-500 ${post.liked ? 'text-blue-500' : ''}`}></i>
                                        <span className="text-sm">{post.likeCount}</span>
                                    </button>
                                    <button onClick={() => openCommentModal(post.id)} className="flex flex-col items-center">
                                        <i className='bx bx-fw bx-message-square-dots hover:text-gray-700'></i>
                                        <span className="text-sm">{post.commentCount}</span>
                                    </button>
                                    <button onClick={() => handleLinkCopy(post.id)} className="flex flex-col items-center">
                                        <i className='bx bx-fw bx-paper-plane hover:text-gray-700'></i>
                                        {linkCopiedMap[post.id] && <span className="text-sm">Link copied</span>}
                                    </button>
                                </div>
                            </div>
                        ))}
                        {isFetching && (
                            <div className="text-center py-10 text-gray-400"><i className='bx bx-loader-circle bx-spin bx-fw'></i>Loading more...</div>
                        )}
                        {!hasMore && (
                            <div className="text-center py-10 text-gray-400">You reached all posts</div>
                        )}
                        
                      

                        
                    </div>
                )}
                
                
            </div>
            
                        
            <CommentModal
                postId={selectedPostId}
                user={user}
                isOpen={isCommentModalOpen}
                onClose={() => setIsCommentModalOpen(false)}
            />
            
              
              
                        <ul className={`lg:hidden grid grid-cols-5 gap-2 justify-around block fixed bottom-0  w-full py-5 pb-8 px-2 text-2xl ${isDarkTheme ? 'bg-black text-white border-black' : 'bg-white'}`}>
              
              
              
                <li>
                  <NavLink
                    to="/feedspage"
                    className={({ isActive }) =>
                      isActive
                        ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                        : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                    }
                  >
                    <i className="bx bxs-news"></i>
                    <span className="text-xs">feeds</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/apps"
                    className={({ isActive }) =>
                      isActive
                        ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                        : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                    }
                  >
                    <i className='bx bxs-grid-alt'></i>
                    <span className="text-xs">apps</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/topic"
                    className={({ isActive }) =>
                      isActive
                        ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                        : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                    }
                  >
                    <i className="bx bxs-conversation"></i>
                    <span className="text-xs">topic</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/follow"
                    className={({ isActive }) =>
                      isActive
                        ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                        : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                    }
                  >
                    <i className="bx bxs-heart"></i>
                    <span className="text-xs">following</span>
                  </NavLink>
                </li>
                {ad ? (
                  <li>
                    <NavLink
                      to="/managers"
                      className={({ isActive }) =>
                        isActive
                          ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                          : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                      }
                    >
                      <i className="bx bxs-edit"></i>
                      <span className="text-xs">Post</span>
                    </NavLink>
                  </li>
                ) : (
                  <li>
                    <NavLink
                      to="/events"
                      className={({ isActive }) =>
                        isActive
                          ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                          : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                      }
                    >
                      <i className="bx bxs-calendar"></i>
                      <span className="text-xs">events</span>
                    </NavLink>
                  </li>
                )}
              </ul>
        </div>
        
        
    );
};

export default FeedsPage;
