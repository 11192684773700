import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { formatDistanceToNowStrict, differenceInMinutes, parseISO } from 'date-fns';
import { toZonedTime, formatInTimeZone } from 'date-fns-tz';
import { Link } from 'react-router-dom';

const CommentModal = ({ postId, user, isOpen, onClose }) => {
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [replyText, setReplyText] = useState('');
    const [serverTimezone, setServerTimezone] = useState('Asia/Yangon'); // Default timezone
    const [replyingTo, setReplyingTo] = useState(null);
    const [commentsLoading, setCommentsLoading] = useState(false);
    const [firstName, setFirstName] = useState('');

    useEffect(() => {
        if (user) {
            const firstWord = user.name.split(' ')[0];
            setFirstName(firstWord);
        }
    }, [user]);

    useEffect(() => {
        if (isOpen && postId && user) {
            fetchComments(postId);
        }
    }, [isOpen, postId, user]);

    const fetchComments = async (postId) => {
        setCommentsLoading(true);
        try {
            const response = await axios.get(`https://www.pixelliongroup.com/feeds/GetComments.php?post_id=${postId}&t=${Date.now()}`);
            if (response.data && Array.isArray(response.data)) {
                const commentsMap = {};
                const commentLikePromises = response.data.map(async (comment) => {
                    const likeResponse = await axios.get(`https://www.pixelliongroup.com/feeds/GetCommentLikeStatus.php`, {
                        params: { comment_id: comment.id, user_id: user.id }
                    });
                    const { like_count, liked } = likeResponse.data;
                    return { ...comment, likeCount: like_count, liked };
                });

                const commentsWithLikes = await Promise.all(commentLikePromises);

                commentsWithLikes.forEach(comment => {
                    comment.replies = [];
                    commentsMap[comment.id] = comment;
                });

                const nestedComments = [];
                Object.values(commentsMap).forEach(comment => {
                    if (comment.parent_id === 0) {
                        nestedComments.push(comment);
                    } else {
                        if (commentsMap[comment.parent_id]) {
                            commentsMap[comment.parent_id].replies.push(comment);
                        }
                    }
                });

                setComments(nestedComments);
            } else {
                setComments([]);
                console.error('No comments returned from the API');
            }
        } catch (error) {
            setComments([]);
            console.error('Error fetching comments:', error);
        } finally {
            setCommentsLoading(false);
        }
    };

    const handleLikeComment = async (commentId) => {
        if (!user) return;

        const allComments = comments.flatMap(comment => [comment, ...comment.replies]);
        const comment = allComments.find(c => c.id === commentId);
        if (!comment) return;

        const action = comment.liked ? 'unlike' : 'like';

        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/LikeComment.php', 
                { comment_id: commentId, user_id: user.id }, 
                { withCredentials: true }
            );

            if (response.data.success) {
                setComments(prevComments => prevComments.map(c => 
                    c.id === commentId ? { ...c, likeCount: c.liked ? c.likeCount - 1 : c.likeCount + 1, liked: !c.liked } : 
                    { ...c, replies: c.replies.map(r => 
                        r.id === commentId ? { ...r, likeCount: r.liked ? r.likeCount - 1 : r.likeCount + 1, liked: !r.liked } : r
                    )}
                ));
            }
        } catch (error) {
            console.error(`Error ${action}ing comment:`, error);
        }
    };

    const handleAddComment = async (e, parentId = 0) => {
        e.preventDefault();

        const text = parentId === 0 ? commentText : replyText;

        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/AddComment.php', {
                post_id: postId,
                user_id: user.id,
                comment_text: text,
                parent_id: parentId
            });

            if (response.data.success) {
                if (parentId === 0) {
                    setCommentText('');
                } else {
                    setReplyText('');
                    setReplyingTo(null);
                }
                fetchComments(postId);
            } else {
                console.error('Error adding comment');
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const formatDate = (date) => {
        const postDate = toZonedTime(parseISO(date), serverTimezone);
        const now = new Date();
        const zonedNow = toZonedTime(now, serverTimezone);
        const minutesAgo = differenceInMinutes(zonedNow, postDate);

        if (minutesAgo < 60) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' minutes', 'm').replace(' minute', 'm');
        } else if (minutesAgo < 1440) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' hours', 'h').replace(' hour', 'h');
        } else {
            return formatInTimeZone(postDate, serverTimezone, 'd MMM yyyy');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col justify-end z-99">
            <div className="bg-white rounded-t-lg h-3/4 flex flex-col">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-lg font-semibold">Comments</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
                        <i className="bx bx-x text-2xl"></i>
                    </button>
                </div>
                <div className="flex-1 overflow-y-auto p-4">
                    {commentsLoading ? (
                        <div className="text-center py-10 text-slate-400">
                            <i className='bx bx-loader-circle bx-spin bx-fw'></i>Loading comments...
                        </div>
                    ) : (
                        comments.length > 0 ? comments.map(comment => {
                            const isApproved = comment.status === 'approved';
                            const isPendingByCurrentUser = comment.status === 'pending' && comment.user_id === user.id;
                            return (isApproved || isPendingByCurrentUser) ? (
                                <div key={comment.id} className="mt-2">
                                    <div className="flex items-start space-x-2">
                                        <img src={comment.profile_image} alt={`${comment.user_name}'s profile`} className="w-10 h-10 rounded-full border border-gray-200"/>
                                        <div className="bg-slate-50 rounded-lg p-5 w-full">
                                            <p className="font-semibold">{comment.user_name} <span className="text-xs text-gray-500"> {formatDate(comment.created_at)}</span></p>
                                            <p>{comment.comment_text}</p>
                                            {comment.status === 'pending' && comment.user_id === user.id && <p className="text-xs text-gray-500 mt-1">Pending</p>}
                                            <div className="flex items-center space-x-2 mt-2">
                                                <button onClick={() => handleLikeComment(comment.id)} className={`text-sm ${comment.liked ? 'text-blue-500' : 'text-gray-500'} hover:underline`}>
                                                    {comment.liked ? 'Unlike' : 'Like'}
                                                </button>
                                                <span className="text-xs text-gray-500">{comment.likeCount} likes</span>
                                                <button onClick={() => setReplyingTo(comment.id)} className="text-sm text-blue-500 hover:underline">Reply</button>
                                            </div>
                                        </div>
                                    </div>
                                    {comment.replies && comment.replies.map(reply => (
                                        <div key={reply.id} className="ml-5 mt-2 flex items-start space-x-2">
                                            <img src={reply.profile_image} alt={`${reply.user_name}'s profile`} className="w-8 h-8 rounded-full border border-gray-200"/>
                                            <div className="bg-slate-100 rounded-lg p-5 w-full">
                                                <p className="font-semibold">{reply.user_name} <span className="text-xs text-gray-500"> {formatDate(reply.created_at)}</span></p>
                                                <p>{reply.comment_text}</p>
                                                {reply.status === 'pending' && reply.user_id === user.id && <p className="text-xs text-gray-500 mt-1">Pending</p>}
                                                <div className="flex items-center space-x-2 mt-2">
                                                    <button onClick={() => handleLikeComment(reply.id)} className={`text-sm ${reply.liked ? 'text-blue-500' : 'text-gray-500'} hover:underline`}>
                                                        {reply.liked ? 'Unlike' : 'Like'}
                                                    </button>
                                                    <span className="text-xs text-gray-500">{reply.likeCount} likes</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {replyingTo === comment.id && (
                                        <form onSubmit={(e) => handleAddComment(e, comment.id)} className="ml-12 mt-2">
                                            <textarea
                                                value={replyText}
                                                onChange={(e) => setReplyText(e.target.value)}
                                                placeholder={`Reply as ${firstName}`}
                                                required
                                                className="w-full p-2 border border-gray-300 rounded-lg"
                                            />
                                            <button type="submit" className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg">Submit</button>
                                        </form>
                                    )}
                                </div>
                            ) : null;
                        }) : <p className="my-8 text-gray-500 w-full text-center"><i className='bx bx-fw bxs-message-square'></i> No comments yet.</p>
                    )}
                </div>
                <form onSubmit={(e) => handleAddComment(e)} className="p-4 border-t">
                    <textarea
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                        placeholder={`Add a comment as ${firstName}`}
                        required
                        className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                    />
                    <button type="submit" className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg mb-24">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default CommentModal;
