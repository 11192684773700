import React from 'react';

const ExUrl = ({ url }) => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src={url}
        style={{ width: '100%', height: '100%' }}
        frameBorder="0"
        allowFullScreen
        title="Embedded URL"
      />
    </div>
  );
};

export default ExUrl;
