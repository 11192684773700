// TheInteractive.js
import React from 'react';

const TheSimple = ({ clientname }) => {
  return (
    <div>
      <h2>Interactive Component for {clientname}</h2>
      {/* Add your interactive content here */}
    </div>
  );
};

export default TheSimple;
