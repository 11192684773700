import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getSession, clearSession } from './comp/session';
import TheInteractive from './inter/TheInteractive';
import TheSimple from './inter/TheSimple';
import { format, formatDistanceToNowStrict, differenceInMinutes, parseISO } from 'date-fns';
import { toZonedTime, formatInTimeZone } from 'date-fns-tz';
import PhotoModal from './comp/PhotoModal';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import ReactPlayer from 'react-player'

const ClientProfilePage = () => {

    const { username } = useParams();
    const { clientname } = useParams();
    const [user, setUser] = useState(null);
    const [client, setClient] = useState(null);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serverTimezone, setServerTimezone] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [successPhoto, setSuccessPhoto] = useState('');
    const [successImage, setSuccessImage] = useState('');
    const [successMap, setSuccessMap] = useState({});
    const [firstName, setFirstName] = useState('');
    const [linkCopiedMap, setLinkCopiedMap] = useState({});
    const [expandedPosts, setExpandedPosts] = useState({});
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [clientLoading, setClientLoading] = useState(true);
	const [followerCount, setFollowerCount] = useState(0);

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const user = JSON.parse(userData);
            const firstWord = user.name.split(' ')[0];
            setUser(user);
            setFirstName(firstWord);
        }
        setLoading(false);
    }, []);

   useEffect(() => {
    const sessionUser = getSession('user');
    if (sessionUser) {
        setUser(sessionUser);
    } else {
        navigate('/login'); // Redirect to login if no session
    }
}, []);


useEffect(() => {
    if (user) {
        fetchClientInfo(clientname);
    }
}, [user, clientname]);



    const handleLinkCopy = (postId) => {
        const link = `https://feeds.ygn.me/post/${postId}`;
        navigator.clipboard.writeText(link).then(() => {
            setLinkCopiedMap(prevState => ({ ...prevState, [postId]: true }));
            setTimeout(() => {
                setLinkCopiedMap(prevState => ({ ...prevState, [postId]: false }));
            }, 2000); // Hide message after 2 seconds
        });
    };

    const openModal = (photo) => {
        setSelectedPhoto(photo);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedPhoto(null);
        setModalIsOpen(false);
    };

    const fetchLinkPreview = async (url) => {
        try {
            const response = await axios.get(`https://www.pixelliongroup.com/link_preview_api.php?url=${encodeURIComponent(url)}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching link preview:', error);
            return null;
        }
    };

    const fetchClients = async (clientIds) => {
        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/ClientService.php', { clientIds });
            const clients = response.data.clients || {};
            return clients;
        } catch (error) {
            console.error('Error fetching clients:', error);
            return {};
        }
    };

 const fetchClientInfo = async (clientname) => {
    setClientLoading(true);  // Start loading
    try {
        const response = await axios.get('https://www.pixelliongroup.com/feeds/ClientProfile.php', {
            params: { t: Date.now(), username }
        });
        if (response.data.client) {
            setClient(response.data.client);
            if (user && response.data.client.id) {
                checkIfFollowing(user.id, response.data.client.id);
                fetchFollowerCount(response.data.client.id); // Fetch follower count here
            }
        } else {
            console.error('Client not found');
        }
    } catch (error) {
        console.error('Error fetching client info:', error);
    } finally {
        setClientLoading(false);  // End loading
    }
};




    const fetchPosts = async (userId, page = 1) => {
        setIsFetching(true);
        try {
            const response = await axios.get('https://www.pixelliongroup.com/feeds/PostClient.php', {
                params: { t: Date.now(), username, user_id: userId, page, per_page: 5 }
            });

            const { posts, server_timezone } = response.data;

            const postsWithCounts = await Promise.all(posts.map(async post => {
                const countsResponse = await axios.get('https://www.pixelliongroup.com/feeds/GetLikeCount.php', {
                    params: { post_id: post.id, user_id: userId }
                });

                const { like_count, liked, comment_count } = countsResponse.data;

                let linkPreview = null;
                if (post.type === 'link') {
                    linkPreview = await fetchLinkPreview(JSON.parse(post.content).link);
                }

                return { ...post, likeCount: like_count, liked, commentCount: comment_count, linkPreview };
            }));

            setPosts(prevPosts => {
                const postIds = new Set(prevPosts.map(post => post.id));
                const newPosts = postsWithCounts.filter(post => !postIds.has(post.id));
                return [...prevPosts, ...newPosts];
            });

            setServerTimezone(server_timezone);
            setHasMore(posts.length === 5);
        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setIsFetching(false);
            setLoading(false);
        }
    };

    const handleLike = async (postId) => {
        const storedUser = JSON.parse(sessionStorage.getItem('user'));
        if (!storedUser) {
            console.error('No user found in session storage');
            return;
        }

        const post = posts.find(post => post.id === postId);
        if (!post) {
            console.error('Post not found');
            return;
        }

        const action = post.liked ? 'unlike' : 'like';

        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/LikePost.php', 
                { post_id: postId, user: storedUser }, 
                { withCredentials: true }
            );

            setPosts(prevPosts => prevPosts.map(post => 
                post.id === postId ? { 
                    ...post, 
                    likeCount: post.liked ? post.likeCount - 1 : post.likeCount + 1, 
                    liked: !post.liked 
                } : post
            ));
        } catch (error) {
            console.error(`Error ${action}ing post:`, error);
        }
    };

    const formatDate = (date) => {
        const postDate = toZonedTime(parseISO(date), serverTimezone);
        const now = new Date();
        const zonedNow = toZonedTime(now, serverTimezone);
        const minutesAgo = differenceInMinutes(zonedNow, postDate);

        if (minutesAgo < 60) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' minutes', 'm').replace(' minute', 'm');
        } else if (minutesAgo < 1440) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' hours', 'h').replace(' hour', 'h');
        } else {
            return formatInTimeZone(postDate, serverTimezone, 'd MMM yyyy');
        }
    };

    const createMarkup = (text) => {
        const html = text.replace(/\n/g, '<br />');
        return { __html: html };
    };

    const handleSuccess = (postId, photo, text) => {
        setSuccessMap(prevState => ({
            ...prevState,
            [postId]: { photo, text }
        }));
    };

    const renderContent = (content, postId) => {
        let parsedContent;
        try {
            parsedContent = JSON.parse(content);
            parsedContent.inter = parsedContent.inter.replace(/{firstName}/g, firstName);
        } catch (error) {
            console.error('Error parsing content:', error);
            return null;
        }

        return parse(parsedContent.inter, {
            replace: (domNode) => {
                if (domNode.name && domNode.name.toLowerCase() === 'theinteractive') {
                    const { clientname, code } = domNode.attribs;
                    return (
                        <TheInteractive
                            clientname={clientname}
                            code={code}
                            firstName={firstName}
                            onSuccess={() => {
                                handleSuccess(postId, parsedContent.photo, parsedContent.text);
                            }}
                        />
                    );
                }
            }
        });
    };

    const renderContentAA = (content) => {
        return parse(content, {
            replace: (domNode) => {
                if (domNode.name && domNode.name.toLowerCase() === 'thesimple') {
                    const clientname = domNode.attribs.clientname;
                    return <TheSimple clientname={clientname} />;
                }
            }
        });
    };

    const loadMore = () => {
        if (!isFetching && hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        if (user) {
            fetchPosts(user.id, page);
        }
    }, [page, user]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
                loadMore();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    const exceedsWordLimit = (text, wordLimit) => {
        return text.split(' ').length > wordLimit;
    };

    const toggleExpanded = (postId) => {
        setExpandedPosts(prevState => ({
            ...prevState,
            [postId]: !prevState[postId]
        }));
    };

   const checkIfFollowing = async (userId, clientId) => {
    try {
        const response = await axios.get('https://www.pixelliongroup.com/feeds/CheckFollowing.php', {
            params: { t: Date.now(), user_id: userId, client_id: clientId }
        });
        setIsFollowing(response.data.isFollowing);
    } catch (error) {
        console.error('Error checking following status:', error);
    }
};


    const handleFollow = async () => {
        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/FollowClient.php', {
                user_id: user.id,
                client_id: client.id
            });
            if (response.data.status === 'success') {
                setIsFollowing(true);
                setFollowerCount(prevCount => prevCount + 1);
            }
        } catch (error) {
            console.error('Error following client:', error);
        }
    };

    const handleUnfollow = async () => {
        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/UnfollowClient.php', {
                user_id: user.id,
                client_id: client.id
            });
            if (response.data.status === 'success') {
                setIsFollowing(false);
                setFollowerCount(prevCount => prevCount - 1);
            }
        } catch (error) {
            console.error('Error unfollowing client:', error);
        }
    };
    
    const fetchFollowerCount = async (clientId) => {
    try {
        const response = await axios.post('https://www.pixelliongroup.com/feeds/GetFollowCount.php', {
            client_id: clientId
        });
        if (response.data.status === 'success') {
            setFollowerCount(response.data.follower_count);
        } else {
            console.error('Failed to fetch follower count');
        }
    } catch (error) {
        console.error('Error fetching follower count:', error);
    }
};


    return (
        <div className="lg:col-span-6 col-span-12">
            {clientLoading ? (
                <div className="text-center py-10 text-slate-400">
                    <i className='bx bx-loader-circle bx-spin bx-fw'></i> Loading...
                </div>
            ) : client ? (
                <div>
                    <div className="">
                        <img 
                            src={client.cover_photo} 
                            alt="Client Profile" 
                            className="w-full"
                        />
                    </div>
                    <div className="px-5 py-5 border-1 border-slate-200 lg:flex block items-center relative">
                        <img 
                            src={client.profile_picture} 
                            alt="Client Profile" 
                            className="lg:w-24 lg:h-24 w-32 h-32 -mt-20 lg:mt-0 lg:mb-0 mb-5 rounded-full border border-1 border-slate-100 mr-4"
                        />
                        <div className="w-full ">
                        <div className="">
                                                       <p className="text-lg raleway-600">{client.name} {client.verify == 1 && <i className='bx bxs-bolt-circle text-yellow-500 '></i>} </p>
                            <p className="text-xs text-slate-500">
                                <Link to={`/${client.username}`}>@{client.username}</Link> · {client.industry}
                            </p>
                            <p>{client.bio}</p>
                            </div>
                            <div className="right-5 top-5 absolute"><p className="hidden">Followers: {followerCount}</p>
                             {user && (
                                <button 
                                    onClick={isFollowing ? handleUnfollow : handleFollow} 
                                    className={`text-sm uppercase px-3 py-2 rounded-lg border border-1 border-slate-200 hover:bg-slate-50 ${isFollowing ? 'text-slate-500' : ' text-slate-500'}`}
                                >
                                    {isFollowing ? 'Following' : 'Follow'}
                                </button>
                            )}
							
                            </div>
                            
                        </div>
                    </div>
                    <div className="p-5 flex space-x-2" dangerouslySetInnerHTML={{ __html: client.cta }} />
                </div>
            ) : (
                <div>
                    <p className="text-lg raleway-600">Client not available</p>
                </div>
            )}
           <h1 className="lg:text-lg text-sm raleway-600 px-10 px-5 py-5 uppercase border-b border-1 border-slate-200 sticky top-0 bg-white">
                <i className='bx bxs-news bx-fw'></i>feeds
            </h1>
            {loading ? (
                <div className="text-center py-10 text-slate-400"><i className='bx bx-loader-circle bx-spin bx-fw'></i></div>
            ) : (
                <div className="">
                    {posts.map(post => (
                        <div key={post.id} className="p-5 lg:border-1 lg:border-b border-b-8 border-slate-200">
                            <div className="flex items-center mb-4">
                                {post.client_name ? (
                                    <>
                                        <img 
                                            src={post.client_profile_picture} 
                                            alt="Client Profile" 
                                            className="w-10 h-10 rounded-full border border-1 border-slate-100 mr-4"
                                        />
                                        <div>
                                            <p className="text-lg raleway-600">{post.client_name} {post.client_verify == 1 && <i className='bx bxs-bolt-circle text-yellow-500 '></i>} </p>
                                            <p className="text-xs text-slate-500">
                                                <Link to={`/u/${post.client_username}`}>
                                                    @{post.client_username}
                                                </Link> · {post.client_industry} · {formatDate(post.created_at)}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <p className="text-lg raleway-600">Client not available</p>
                                    </div>
                                )}
                            </div>
                           {post.type === 'text' && (
                                <p style={{ whiteSpace: 'pre-line' }} className="lg:ml-14 text-gray-700 mb-4 leading-relaxed lg:text-lg text-sm">
                                    {expandedPosts[post.id] ? post.content : truncateText(post.content.replace(/(\r\n|\n|\r)/gm, "\\n"), 20)}
                                    {exceedsWordLimit(post.content, 20) && (
                                        <button 
                                            onClick={() => toggleExpanded(post.id)} 
                                            className="text-blue-500 hover:underline ml-2"
                                        >
                                            {expandedPosts[post.id] ? 'Read Less' : 'Read More'}
                                        </button>
                                    )}
                                </p>
                            )}

                            {post.type === 'photo_text' && (
                                <div className="lg:ml-14">
                                    {(() => {
                                        let content;
                                        try {
                                            content = JSON.parse(post.content.replace(/(\r\n|\n|\r)/gm, "\\n"));
                                        } catch (error) {
                                            console.error('Error parsing content:', error);
                                            return <p className="text-gray-700 mb-4">Error loading content</p>;
                                        }

                                        if (Array.isArray(content.photos)) {
                                            return (
                                                <>
                                                    <div className="grid-cols-2 grid gap-1 mb-2">
                                                        {content.photos.map((photo, index) => (
                                                            <img 
                                                                key={index} 
                                                                src={photo} 
                                                                alt={`Photo ${index + 1}`} 
                                                                className="w-full object-cover rounded-lg cursor-pointer border"
                                                                onClick={() => openModal(photo)}
                                                            />
                                                        ))}
                                                    </div>
                                                </>
                                            );
                                        } else if (content.photo) {
                                            return (
                                                <>
                                                    <img 
                                                        src={content.photo} 
                                                        alt="Photo" 
                                                        className="mb-2 w-full object-cover rounded-lg cursor-pointer border"
                                                        onClick={() => openModal(content.photo)}
                                                    />
                                                </>
                                            );
                                        } else {
                                            return <p className="text-gray-700 mb-4">No photos available</p>;
                                        }
                                    })()}
                                    <p style={{ whiteSpace: 'pre-line' }} className="lg:text-lg text-sm text-gray-900 mb-4 leading-relaxed text-lg p-5 bg-slate-50 rounded-lg">
                                        {expandedPosts[post.id] ? JSON.parse(post.content.replace(/(\r\n|\n|\r)/gm, "\\n")).text : truncateText(JSON.parse(post.content.replace(/(\r\n|\n|\r)/gm, "\\n")).text, 20)}
                                        {exceedsWordLimit(JSON.parse(post.content.replace(/(\r\n|\n|\r)/gm, "\\n")).text, 20) && (
                                            <button 
                                                onClick={() => toggleExpanded(post.id)} 
                                                className="text-blue-500 hover:underline ml-2"
                                            >
                                                {expandedPosts[post.id] ? 'Read Less' : 'Read More'}
                                            </button>
                                        )}
                                    </p>
                                </div>
                            )}

                            {post.type === 'html' && (
                                <div className="lg:ml-14 mb-5">
                                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                                </div>
                            )}

                            {post.type === 'inter_text' && (
                                <div className="lg:ml-14 mb-5">
                                    {successMap[post.id] ? (
                                        <div>
                                            <img src={successMap[post.id].photo} alt="Success" className="w-full rounded-lg" />
                                            <p className="mt-4 text-lg">{successMap[post.id].text}</p>
                                        </div>
                                    ) : (
                                        renderContent(post.content, post.id)
                                    )}
                                </div>
                            )}

                            {post.type === 'inter' && (
                                <div className="lg:ml-14 mb-5">
                                    {renderContentAA(post.content)}
                                </div>
                            )}

                            {post.type === 'event' && (() => {
                                let content;
                                try {
                                    const cleanedContent = post.content.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, " ");
                                    content = JSON.parse(cleanedContent);
                                } catch (error) {
                                    return <p className="text-gray-700 mb-4">Error loading event content</p>;
                                }

                                return (
                                    <div className="lg:ml-14 mb-5">
                                        <p className="my-5 lg:text-lg">
                                            {expandedPosts[post.id] ? content.content : truncateText(content.content, 20)}
                                            {exceedsWordLimit(content.content, 20) && (
                                                <button 
                                                    onClick={() => toggleExpanded(post.id)} 
                                                    className="text-blue-500 hover:underline ml-2"
                                                >
                                                    {expandedPosts[post.id] ? 'Read Less' : 'Read More'}
                                                </button>
                                            )}
                                        </p>
                                        <div className="border p-5 rounded-lg">
                                            <img src={content.photo} alt="Event" className="w-full rounded-lg mb-4" />
                                            <a href={content.url}><h2 className="raleway-600 leading-5 lg:text-3xl text-lg font-bold mb-2">{content.title}</h2></a>
                                            <p className="text-slate-500 text-sm mb-2"><i className='bx bxs-calendar bx-fw'></i>{content.date} <i className='bx bxs-time bx-fw' ></i>{content.time} <i className='bx bxs-info-circle bx-fw'></i>{content.price} <i className='bx bxs-buildings bx-fw'></i>{content.type}</p>
                                            <p className="text-slate-700 lg:text-lg text-sm">
                                                {truncateText(content.text, 20)}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })()}

                            {post.type === 'link' && post.linkPreview && (
                                <div className="lg:ml-14 lg:text-lg">
                                    {expandedPosts[post.id] ? JSON.parse(post.content).text : truncateText(JSON.parse(post.content).text, 20)}
                                    {exceedsWordLimit(JSON.parse(post.content).text, 20) && (
                                        <button 
                                            onClick={() => toggleExpanded(post.id)} 
                                            className="text-blue-500 hover:underline ml-2"
                                        >
                                            {expandedPosts[post.id] ? 'Read Less' : 'Read More'}
                                        </button>
                                    )}
                                    <a href={post.content} className="">
                                        <div className="border hover:bg-white mb-5 hover:ring hover:ring-5 hover:ring-slate-200 p-4 mt-2 rounded-lg grid grid-cols-2 gap-10">
                                            <div className="lg:col-span-1 col-span-2">{post.linkPreview.image && <img src={post.linkPreview.image} alt="Preview" className="rounded-lg w-full h-48 object-cover" />}</div>
                                            <div className="lg:col-span-1 col-span-2">
                                                <h2 className="text-lg font-bold mt-2">{post.linkPreview.title}</h2>
                                                <p className="text-sm text-gray-600">{post.linkPreview.description}</p>
                                                <p className="text-sm text-gray-600 mt-5"><i className='bx bx-world bx-fw'></i>{post.linkPreview.domain}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )}

                            {post.type === 'video' && (
                                <div className="lg:ml-14 mb-5">
                                         <div className="rounded-lg bg-black overflow-hidden w-full" >
                                    <ReactPlayer className="react-player w-full h-full" width="100%" height="100%" muted={true} playing={true} loop={true}  controls={true} url={JSON.parse(post.content).video} />
                                    </div>
                                    <p style={{ whiteSpace: 'pre-line' }} className="text-gray-900 my-5 lg:text-lg text-sm leading-relaxed text-lg p-5 bg-slate-50 rounded-lg">
                                        {expandedPosts[post.id] ? JSON.parse(post.content).text : truncateText(JSON.parse(post.content).text, 20)}
                                        {exceedsWordLimit(JSON.parse(post.content).text, 20) && (
                                            <button 
                                                onClick={() => toggleExpanded(post.id)} 
                                                className="text-blue-500 hover:underline ml-2"
                                            >
                                                {expandedPosts[post.id] ? 'Read Less' : 'Read More'}
                                            </button>
                                        )}
                                    </p>
                                </div>
                            )}
                            <div className="lg:ml-14 space-x-2 text-slate-500 text-lg">
                                <button onClick={() => handleLike(post.id)}>
                                    <i 
                                        className={`bx bxs-like bx-fw hover:text-blue-500 ${post.liked ? 'text-blue-500 ' : ''}`} 
                                    ></i>
                                </button>
                                {post.likeCount} 
                                <Link to={`/post/${post.id}`}><i className='bx bx-fw bxs-message-square-dots hover:text-slate-700'></i>{post.commentCount}</Link>
                                <i 
                                    className='bx bx-fw bxs-paper-plane hover:text-slate-700 cursor-pointer' 
                                    onClick={() => handleLinkCopy(post.id)}
                                ></i>
                                {linkCopiedMap[post.id] && <span className="bg-slate-50 rounded-lg px-3 py-2 text-sm"><i className='bx bx-link-alt bx-fw' ></i>Link copied</span>}
                            </div>
                        </div>
                    ))}
                    {isFetching && (
                        <div className="text-center py-10 text-slate-400"><i className='bx bx-loader-circle bx-spin bx-fw'></i>Loading more...</div>
                    )}
                    {!hasMore && (
                        <div className="text-center py-10 text-slate-400">You reached all posts</div>
                    )}
                </div>
            )}
            <PhotoModal isOpen={modalIsOpen} onClose={closeModal} photo={selectedPhoto} />
        </div>
    );
};

export default ClientProfilePage;
