import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { formatDistanceToNowStrict, differenceInMinutes, parseISO } from 'date-fns';
import { toZonedTime, formatInTimeZone } from 'date-fns-tz';
import TheInteractive from './inter/TheInteractive';
import TheSimple from './inter/TheSimple';
import PhotoModal from './comp/PhotoModal';
import parse from 'html-react-parser';

// Memoize components
const MemoizedTheInteractive = React.memo(TheInteractive);
const MemoizedTheSimple = React.memo(TheSimple);
const MemoizedPhotoModal = React.memo(PhotoModal);

const SinglePage = () => {
    const { postId } = useParams();
    const [user, setUser] = useState(null);
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [loading, setLoading] = useState(true);
    const [serverTimezone, setServerTimezone] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [successMap, setSuccessMap] = useState({});
    const [firstName, setFirstName] = useState('');
    const [linkCopied, setLinkCopied] = useState(false); // New state for link copied
    const navigate = useNavigate();
    const [commentsLoading, setCommentsLoading] = useState(false);


    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    useEffect(() => {
        const userData = sessionStorage.getItem('user'); // Changed to session storage
        if (userData) {
            const user = JSON.parse(userData);
            console.log('User Data:', user); // Debugging log
            const firstWord = user.name.split(' ')[0];
            setUser(user);
            setFirstName(firstWord);
            setLoading(false);
        } else {
            navigate('/login'); // Redirect to login if no session
        }
    }, [navigate]);

    const fetchPostData = useCallback(async () => {
    if (!user) return;

    setLoading(true);
    try {
        const response = await axios.get(`https://www.pixelliongroup.com/feeds/SinglePost.php?postid=${postId}`);
        const { post, server_timezone } = response.data;

        if (post) {
            const countsResponse = await axios.get('https://www.pixelliongroup.com/feeds/GetLikeCount.php', {
                params: { post_id: post.id, user_id: user.id, t: Date.now() }
            });

            const { like_count, liked, comment_count } = countsResponse.data;

            let linkPreview = null;
            if (post.type === 'link') {
                linkPreview = await fetchLinkPreview(JSON.parse(post.content).link);
            }

            const clientIds = [post.client_id];
            const clientData = await fetchClients(clientIds);

            setPost({
                ...post,
                likeCount: like_count,
                liked,
                commentCount: comment_count, // Include comment count
                linkPreview,
                client: clientData[post.client_id]
            });

            setServerTimezone(server_timezone);
            fetchComments(post.id); // Fetch comments for the post
        }
    } catch (error) {
        console.error('Error fetching post:', error);
    } finally {
        setLoading(false);
    }
}, [postId, user]);

   useEffect(() => {
    if (user) {
        fetchPostData();
    }
}, [fetchPostData, user, postId]); // Include postId as a dependency


    const fetchComments = useCallback(async (postId) => {
    setCommentsLoading(true); // Start loading
    try {
        const response = await axios.get(`https://www.pixelliongroup.com/feeds/GetComments.php?post_id=${postId}&t=${Date.now()}`);
        console.log('Fetched Comments:', response.data); // Debugging log
        if (response.data && Array.isArray(response.data)) {
            setComments(response.data);
        } else {
            setComments([]); // Ensure comments are reset if no valid response
            console.error('No comments returned from the API');
        }
    } catch (error) {
        setComments([]); // Ensure comments are reset on error
        console.error('Error fetching comments:', error);
    } finally {
        setCommentsLoading(false); // Stop loading
    }
}, []);


    const fetchLinkPreview = async (url) => {
        try {
            const response = await axios.get(`https://www.pixelliongroup.com/link_preview_api.php?url=${encodeURIComponent(url)}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching link preview:', error);
            return null;
        }
    };

    const fetchClients = async (clientIds) => {
        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/ClientService.php', { clientIds });
            const clients = response.data.clients || {};
            return clients;
        } catch (error) {
            console.error('Error fetching clients:', error);
            return {};
        }
    };

    const openModal = (photo) => {
        setSelectedPhoto(photo);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedPhoto(null);
        setModalIsOpen(false);
    };

    const handleLike = async (postId) => {
        const storedUser = JSON.parse(sessionStorage.getItem('user'));
        if (!storedUser) {
            console.error('No user found in session storage');
            return;
        }

        const action = post.liked ? 'unlike' : 'like';

        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/LikePost.php', 
                { post_id: postId, user: storedUser }, 
                { withCredentials: true }
            );
            console.log(`${action} response:`, response.data);
            setPost(prevPost => ({
                ...prevPost, 
                likeCount: post.liked ? post.likeCount - 1 : post.likeCount + 1, 
                liked: !post.liked
            }));
        } catch (error) {
            console.error(`Error ${action}ing post:`, error);
        }
    };

    const formatDate = (date) => {
        const postDate = toZonedTime(parseISO(date), serverTimezone);
        const now = new Date();
        const zonedNow = toZonedTime(now, serverTimezone);
        const minutesAgo = differenceInMinutes(zonedNow, postDate);

        if (minutesAgo < 60) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' minutes', 'm').replace(' minute', 'm');
        } else if (minutesAgo < 1440) { // 1440 minutes in a day
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' hours', 'h').replace(' hour', 'h');
        } else {
            return formatInTimeZone(postDate, serverTimezone, 'd MMM yyyy');
        }
    };

    const handleSuccess = (postId, photo, text) => {
        setSuccessMap(prevState => ({
            ...prevState,
            [postId]: { photo, text }
        }));
    };

    const renderContentAA = (content) => {
        return parse(content, {
            replace: (domNode) => {
                if (domNode.name && domNode.name.toLowerCase() === 'thesimple') {
                    const clientname = domNode.attribs.clientname;
                    return <MemoizedTheSimple clientname={clientname} />;
                }
            }
        });
    };

    const renderContent = (content, postId) => {
        let parsedContent;
        try {
            parsedContent = JSON.parse(content);
            parsedContent.inter = parsedContent.inter.replace(/{firstName}/g, firstName);
        } catch (error) {
            console.error('Error parsing content:', error);
            return null;
        }

        return parse(parsedContent.inter, {
            replace: (domNode) => {
                if (domNode.name && domNode.name.toLowerCase() === 'theinteractive') {
                    const { clientname, code } = domNode.attribs;
                    return (
                        <MemoizedTheInteractive
                            clientname={clientname}
                            code={code}
                            firstName={firstName}
                            onSuccess={() => {
                                handleSuccess(postId, parsedContent.photo, parsedContent.text);
                            }}
                        />
                    );
                }
            }
        });
    };

    const handleAddComment = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/AddComment.php', {
                post_id: postId,
                user_id: user.id,
                comment_text: commentText
            });

            if (response.data.success) {
                setCommentText('');
                fetchComments(postId); // Refresh comments
            } else {
                console.error('Error adding comment');
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const handleLinkCopy = () => {
        const link = `https://feeds.ygn.me/post/${postId}`;
        navigator.clipboard.writeText(link).then(() => {
            setLinkCopied(true);
            setTimeout(() => {
                setLinkCopied(false);
            }, 2000); // Hide message after 2 seconds
        });
    };

    
  if (loading) {
	    return (
	            <div className="text-center py-10 text-slate-400 col-span-6"><i className='bx bx-loader-circle bx-spin bx-fw'></i></div>)
    }
    
    if (!post) {
        return null; // Do not render anything if the post is not found
    }
    
  

    return (
        <div className="col-span-6">
            <h1 className="text-lg raleway-600 px-10 px-5 py-5 uppercase border-b border-1 border-slate-200">
                <button className="uppercase" onClick={goBack}><i class='bx bx-chevron-left-circle bx-fw '></i> Back Feeds</button>
            </h1>
            {loading? (
	            <div className="text-center py-10 text-slate-400 col-span-6"><i className='bx bx-loader-circle bx-spin bx-fw'></i></div>)
   : ( null ) }
            <div className="p-5 border-b border-1 border-slate-200">
                <div className="flex items-center mb-4">
                    {post.client ? (
                        <>
                            <img 
                                src={post.client.profile_picture} 
                                alt="Client Profile" 
                                className="w-10 h-10 rounded-full border border-1 border-slate-100 mr-4"
                            />
                            <div>
                                <p className="text-lg raleway-600">{post.client.name} {post.client.verify == 1 && <i className='bx bxs-bolt-circle text-yellow-500 '></i>} </p>
                                <p className="text-xs text-slate-500"><Link to={`/u/${post.client.username}`}>@{post.client.username}</Link> · {post.client.industry} · {formatDate(post.created_at)}</p>
                            </div>
                        </>
                    ) : (
                        <div>
                            <p className="text-lg raleway-600">Client not available</p>
                        </div>
                    )}
                </div>
  
                
                {post.type === 'event' && (() => {
                                let content;
                                try {
                                    const cleanedContent = post.content.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, " ");
                                    content = JSON.parse(cleanedContent);
                                } catch (error) {
                                    return <p className="text-gray-700 mb-4">Error loading event content</p>;
                                }

                                return (
                                    <div className="ml-14 mb-5">
                                        <p className="my-5">
                                            {content.content}
                                              </p>
                                        <div className="border p-5 rounded-lg">
                                            <img src={content.photo} alt="Event" className="w-full rounded-lg mb-4" />
                                            <a href={content.url}><h2 className="raleway-600  text-3xl font-bold mb-2">{content.title}</h2></a>
                                            <p className="text-slate-500 text-sm mb-2"><i className='bx bxs-calendar bx-fw'></i>{content.date} <i className='bx bxs-time bx-fw' ></i>{content.time} <i className='bx bxs-info-circle bx-fw'></i>{content.price} <i className='bx bxs-buildings bx-fw'></i>{content.type}</p>
                                            <p className="text-slate-700">
                                                {content.text}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })()}
                
                
                                <div className="ml-14 space-x-2 text-slate-500 text-lg">
                    <button onClick={() => handleLike(post.id)}>
                        <i 
                          className={`bx bxs-like bx-fw hover:text-blue-500 ${post.liked ? 'text-blue-500 ' : ''}`} 
                        ></i>
                    </button>
                    {post.likeCount} 
                    <Link to={`/post/${post.id}`}><i className='bx bx-fw bxs-message-square-dots hover:text-slate-700'></i>{post.commentCount}</Link>
                    <i 
                        className='bx bx-fw bxs-paper-plane hover:text-slate-700 cursor-pointer' 
                        onClick={handleLinkCopy}
                    ></i>
                    {linkCopied && <span className="bg-slate-50 rounded-lg px-3 py-2 text-sm"><i className='bx bx-link-alt bx-fw' ></i>Link copied</span>}
                </div>
            </div>

         

        </div>
    );
};

export default SinglePage;
