import React from 'react';

const PhotoModal = ({ isOpen, onClose, photo }) => {
    if (!isOpen) return null;

    return (
        <div onClick={onClose} className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
          <button onClick={onClose} className="text-white  absolute top-5 right-5"><i class='bx bxs-no-entry bx-sm'></i></button>
            <div className="ring ring-[10px] ring-white  lg:-m-10 rounded-lg m-5">
          
                <img src={photo} alt="Photo" className="w-full max-h-screen object-cover rounded-lg" />
                
            </div>
        </div>
    );
};

export default PhotoModal;
