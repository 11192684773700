import React, { useState, useEffect } from "react";
import { questions } from "./questions";
import QuestionSelector from "./QuestionSelector";
import CardDisplay from "./CardDisplay";
import { useParams, Link, useNavigate } from 'react-router-dom';

const MTarot = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isShuffling, setIsShuffling] = useState(false);
  const [isChoosing, setIsChoosing] = useState(false);
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState('');
   const navigate = useNavigate();
  
     useEffect(() => {
        const userData = sessionStorage.getItem('user'); // Changed to session storage
        if (userData) {
            const user = JSON.parse(userData);
            const firstWord = user.name.split(' ')[0];
            setUser(user);
            setFirstName(firstWord);
            setLoading(false);
        } else {
            navigate('/login'); // Redirect to login if no session
        }
    }, [navigate]);

  useEffect(() => {
    // Load all images
    const loadImages = () => {
      const promises = questions.flatMap((question) =>
        question.cards.map((card) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.src = card.img;
            img.onload = resolve;
          });
        })
      );
      Promise.all(promises).then(() => {
        setIsLoading(false);
      });
    };

    loadImages();
  }, []);

  const handleQuestionSelect = (questionObj) => {
    setSelectedQuestion(questionObj.question);
    setIsShuffling(true);

    // Simulate shuffling delay for 2 seconds
    setTimeout(() => {
      setIsShuffling(false);
      setIsChoosing(true);

      // Simulate choosing delay for 2 seconds
      setTimeout(() => {
        const randomCard = questionObj.cards[Math.floor(Math.random() * questionObj.cards.length)];
        setSelectedCard(randomCard);
        setIsChoosing(false);
      }, 2000); // 2 seconds delay for choosing
    }, 2000); // 2 seconds delay for shuffling
  };

  const handleReset = () => {
    setSelectedQuestion(null);
    setSelectedCard(null);
  };

  if (isLoading) {
    return (
       <div className="lg:col-span-6 col-span-12 mb-10 lg:mb-2">
      <h1 className="lg:text-lg text-sm font-bold px-5 py-5 raleway-600 uppercase border-b border-gray-200 sticky top-0 bg-white">
        <a href="/apps"><i className='bx bx-chevron-left-circle bx-fw'></i></a>App / MTarot <span className="absolute right-5 text-xl">
          <Link to={`/profile/mTarot`}>
            <i className='bx bxs-grid-alt'></i>
          </Link>
        </span>
      </h1>
        <h2 className="text-md text-center my-10 text-slate-500 mb-5">Loading mTarot</h2>
        <div className="flex w-full justify-center -ml-10 my-10">
          <div className="hand">
            <div className="card card-1"><span></span></div>
            <div className="card card-2"><span></span></div>
            <div className="card card-3"><span></span></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="lg:col-span-6 col-span-12 mb-10 lg:mb-2">
      <h1 className="lg:text-lg text-sm font-bold px-5 py-5 raleway-600 uppercase border-b border-gray-200 sticky top-0 bg-white">
       <a href="/apps"><i className='bx bx-chevron-left-circle bx-fw'></i></a>App / MTarot <span className="absolute right-5 text-xl">
          <Link to={`/profile/mTarot`}>
            <i className='bx bxs-grid-alt'></i>
          </Link>
        </span>
      </h1>
      <div className="">
        {!selectedQuestion ? (
          <QuestionSelector questions={questions} onSelect={handleQuestionSelect} />
        ) : isShuffling ? (
          <div className="text-center p-4 my-10">
            <h2 className="text-md text-slate-500 mb-5">ကဒ်များကို မွှေနှောက်နေပါတယ်</h2>
            <div className="flex w-full justify-center -ml-10 my-10">
              <div className="hand">
                <div className="card card-1"><span></span></div>
                <div className="card card-2"><span></span></div>
                <div className="card card-3"><span></span></div>
              </div>
            </div>
          </div>
        ) : isChoosing ? (
          <div className="text-center p-4 my-10">
            <h2 className="text-md text-slate-500 mb-5">သင့်အတွက် ကဒ်တစ်ကဒ် ရွေးခြယ်နေပါတယ်</h2>
            <div className="text-center py-10 text-slate-400 lg:col-span-6 col-span-12">
              <i className='bx bx-loader-circle bx-spin bx-fw'></i>
            </div>
          </div>
        ) : (
          <CardDisplay card={selectedCard} onReset={handleReset} />
        )}
      </div>
    </div>
  );
};

export default MTarot;
