import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TagInput from './comp/TagInput';  // Import the TagInput component

const UserSetting = ({ user }) => {
  const [userData, setUserData] = useState({
    name: '',
    phone: '',
    birthday: '',
    bio: '',
    interest: '',
    email: '',
    profile_image: ''
  });

  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    if (user) {
      fetchUserData(user.id);
    }
  }, [user]);

  const fetchUserData = async (userId) => {
    try {
      const response = await axios.post('https://www.pixelliongroup.com/feeds/getUserData.php', 
        new URLSearchParams({ user_id: userId }), 
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      setUserData({
        name: response.data.name || '',
        phone: response.data.phone || '',
        birthday: response.data.birthday || '',
        bio: response.data.bio || '',
        interest: response.data.interest || '',
        email: response.data.email || '',
        profile_image: response.data.profile_image || ''
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleTagsChange = (tags) => {
    setUserData({ ...userData, interest: tags });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://www.pixelliongroup.com/feeds/updateUserData.php', { ...userData, user_id: user.id });
      const successMessage = response.data.message || 'Your profile updated!';
      setResponseMessage(successMessage);
    } catch (error) {
      setResponseMessage("Error updating user data");
      console.error('Error updating user data:', error);
    }
  };

  return (
    <div className="lg:col-span-6 col-span-12 mb-10 lg:mb-2">
      <h1 className="lg:text-lg text-sm raleway-600 px-5 py-5 uppercase border-b border-gray-200 sticky top-0 bg-white">
        <i className='bx bxs-user-circle bx-fw'></i>BASIC PROFILE
      </h1>

      <div className="m-5 rounded-lg border">
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center w-full mt-10">
            <img src={userData.profile_image} alt="Profile" className="w-24 h-24 rounded-full mb-4" />
          </div>
          <div className="grid grid-cols-2 gap-5 p-5">
            <div>
              <label className="pl-1 w-full text-sm text-gray-400">Name*</label>
              <input className="w-full bg-slate-50 rounded-lg border px-3 py-2 mt-1" type="text" name="name" value={userData.name} onChange={handleChange} required />
            </div>
            <div>
              <label className="pl-1 w-full text-sm text-gray-400">Email</label>
              <input className="w-full bg-slate-50 rounded-lg border px-3 py-2 mt-1" type="email" name="email" value={userData.email} readOnly />
            </div>
            <div>
              <label className="pl-1 w-full text-sm text-gray-400">Phone</label>
              <input className="w-full bg-slate-50 rounded-lg border px-3 py-2 mt-1" type="text" name="phone" value={userData.phone} onChange={handleChange} />
            </div>
            <div>
              <label className="pl-1 w-full text-sm text-gray-400">Birthday:</label>
              <input className="w-full bg-slate-50 rounded-lg border px-3 py-2 mt-1" type="date" name="birthday" value={userData.birthday} onChange={handleChange} />
            </div>
          </div>
          <div className="px-5">
            <label className="pl-1 w-full text-sm text-gray-400">Short Bio</label>
            <textarea maxLength="200" className="w-full bg-slate-50 rounded-lg border px-3 py-2 mt-1" name="bio" value={userData.bio} onChange={handleChange}></textarea>
          </div>
          <div className="p-5">
            <label className="pl-1 w-full text-sm text-gray-400">Interest:</label>
            <TagInput value={userData.interest} onChange={handleTagsChange} />  {/* Use the TagInput component */}
          </div>
          <div className="bg-slate-100 rounded-b border-t p-5">
            <button className="py-2 bg-slate-500 text-white rounded-lg w-1/3 right-5" type="submit">Save</button>
          </div>
        </form>
      </div>

      {responseMessage && (
        <div className="flex justify-center">
          <div className={`text-center text-sm bg-gray-50 w-fit px-10 py-2 rounded-full ${responseMessage.toLowerCase().includes('updated') ? 'text-green-500' : 'text-red-500'}`} style={{ marginTop: '10px' }}>
            {responseMessage}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSetting;
