import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ClientList = ({ user }) => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [randomClients, setRandomClients] = useState([]);

    useEffect(() => {
        if (user) {
            fetchClients();
        }
    }, [user]);

    const fetchClients = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://www.pixelliongroup.com/feeds/GetRecList.php', {
                params: { t: Date.now(), user_id: user.id }
            });
            setClients(response.data.clients);
            setRandomClients(getRandomClients(response.data.clients, 3)); // Update here
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFollow = async (clientId) => {
        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/FollowClient.php', {
                user_id: user.id,
                client_id: clientId
            });
            if (response.data.status === 'success') {
                setClients(prevClients => prevClients.filter(client => client.id !== clientId));
                setRandomClients(getRandomClients(clients.filter(client => client.id !== clientId), 3)); // Update here
            }
        } catch (error) {
            console.error('Error following client:', error);
        }
    };

    // Function to get a random subset of clients
    const getRandomClients = (clients, num) => {
        const shuffled = [...clients].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, num);
    };

    return (
        <div>
            <h1 className="text-md raleway-600 px-5 py-5 uppercase">
               <i className='bx bxs-user bx-fw'></i> Recommend to follow
            </h1>
            {loading ? (
                <div className="text-center py-10 text-slate-400">
                    <i className='bx bx-loader-circle bx-spin bx-fw'></i> Loading...
                </div>
            ) : (
                <div className="px-5 py-5 pt-0 space-y-2">
                    {randomClients.length > 0 ? (
                        randomClients.map(client => (
                            <div key={client.id} className="p-5 border border-1 border-slate-200 flex items-center rounded-lg">
                                <img 
                                    src={client.profile_picture} 
                                    alt="Client Profile" 
                                    className="w-10 h-10 rounded-full border border-1 border-slate-100 lg:mr-4 mr-2"
                                />
                                <div className="flex-1">
                                    <p className="text-lg raleway-600">{client.name} {client.verify == 1 && <i className='bx bxs-bolt-circle text-yellow-500 '></i>}</p>
                                    <p className="text-xs text-slate-500">
                                        <Link to={`/u/${client.username}`}>@{client.username}</Link> · {client.industry}
                                    </p>
                                </div>
                                <button 
                                    onClick={() => handleFollow(client.id)} 
                                    className="text-sm uppercase p-2 rounded-lg border border-1 border-slate-200 hover:bg-slate-50 text-slate-500"
                                >
                                    <i className='bx bx-plus bx-fw'></i>
                                </button>
                            </div>
                        ))
                    ) : (
                        <div className="text-center py-10 text-slate-400">
                            No profile available to follow
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ClientList;
