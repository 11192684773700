import React, { useState } from "react";


const Apps = () => {


  
  return (
    <div className="lg:col-span-6 col-span-12 mb-10 lg:mb-2">
      <h1 className="lg:text-lg text-sm font-bold px-5 py-5 raleway-600 uppercase border-b border-gray-200 sticky top-0 bg-white">
        <i className='bx bxs-layer bx-fw'></i>Apps
	</h1>
			<div className="p-5">
						<div className="grid lg:grid-cols-8 grid-cols-4 gap-5">
			<a href="/tarot" className="text-center"><img className="rounded-2xl" src="https://www.pixelliongroup.com/feeds/clients/profile/mtarot.jpg" /> 
			<p className="mt-2 text-sm text-slate-500">mTarot <i className='bx bxs-bolt-circle text-yellow-500 '></i></p>
			</a>
		

								<a href="/urlstory" className="text-center"><img className="rounded-2xl" src="https://urlstory.ygn.me/urlstory.png" /> 
			<p className="mt-2 text-sm text-slate-500">urlstory</p>
			</a>
			
			</div>
			
			</div>
       </div>
  );
};

export default Apps;
