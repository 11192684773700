import React from "react";

const QuestionSelector = ({ questions, onSelect }) => {
  return (
    <div className="px-5 lg:px-24 py-10 text-center">
	    <h2 className="text-2xl font-bold mt-10 text-slate-500">မင်္ဂလာပါ</h2>
      <h2 className="lg:text-5xl text-3xl font-bold mb-10 mt-2 text-amber-500">မေးခွန်းရွေးခြယ်ပါ</h2>
      <p className="my-10 text-xs w-fit text-center w-full"><i class='bx bx-info-circle bx-fw'></i>BETA v.1.0 </p>
      {questions.map((questionObj, index) => (
        <button
          key={index}
          className="bg-white text-slate-500 border rounded-full w-fit mx-2 px-4 py-2 my-2 hover:bg-blue-500 hover:text-white"
          onClick={() => onSelect(questionObj)}
        >
          {questionObj.question}
        </button>
      ))}
    </div>
  );
};

export default QuestionSelector;
