import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider, signInWithPopup, signInWithEmailAndPassword } from './comp/firebase';

const Login = ({ setUser, setFirstName }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // 'success' or 'error'
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleGoogleLogin = async () => {
        setLoading(true);
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            if (!user) {
                setMessage('No user returned from Google login');
                setMessageType('error');
                setLoading(false);
                return;
            }

            const token = await user.getIdToken();
            const email = user.email;
            const name = user.displayName;
            const profileImage = user.photoURL;

            const response = await fetch('https://www.pixelliongroup.com/feeds/UserService.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    token,
                    email,
                    name,
                    profileImage,
                }),
            });

            const data = await response.json();
            if (data.success) {
                const userData = {
                    email: data.user.email,
                    name: data.user.name,
                    id: data.user.id,
                    ad: data.user.manager,
                    profileImage: data.user.profileImage,
                };
                setUser(userData);
                localStorage.setItem('user', JSON.stringify(userData));
                sessionStorage.setItem('user', JSON.stringify(userData));

                const firstWord = userData.name.split(" ")[0];
                setFirstName(firstWord);

                navigate('/feedspage');
            } else {
                setMessage('Login failed');
                setMessageType('error');
            }
        } catch (error) {
            console.error('Error logging in with Google:', error);
            setMessage('Error logging in with Google');
            setMessageType('error');
        } finally {
            setLoading(false);
        }
    };

    const handleEmailLogin = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            const user = result.user;

            if (!user) {
                setMessage('No user returned from email login');
                setMessageType('error');
                setLoading(false);
                return;
            }

            const token = await user.getIdToken();
            const userEmail = user.email;
            const name = user.displayName || user.email.split('@')[0];
            const profileImage = user.photoURL || 'defaultProfileImageURL';

            const response = await fetch('https://www.pixelliongroup.com/feeds/UserService.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    token,
                    email: userEmail,
                    name,
                    profileImage,
                }),
            });

            const data = await response.json();
            if (data.success) {
                const userData = {
                    email: data.user.email,
                    name: data.user.name,
                    id: data.user.id,
                    profileImage: data.user.profileImage,
                };
                setUser(userData);
                localStorage.setItem('user', JSON.stringify(userData));
                sessionStorage.setItem('user', JSON.stringify(userData));

                const firstWord = userData.name.split(" ")[0];
                setFirstName(firstWord);

                navigate('/feedspage');
            } else {
                setMessage('Login failed');
                setMessageType('error');
            }
        } catch (error) {
            console.error('Error logging in with email:', error);
            setMessage('Error logging in with email');
            setMessageType('error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-md mx-auto mt-10 lg:col-span-6 col-span-12 p-10">
            <div className="">
                <h1 className="text-5xl raleway-800 text-bold uppercase text-yellow-500">Login to feeds 👋</h1>
                <span className="text-xs text-slate-500">BY PIXELSAIM</span>
                <p className="my-5 text-slate-500">By continuing you agree to our terms of service and privacy policy.</p>
                <button
                    onClick={handleGoogleLogin}
                    className="w-full bg-white hover:bg-slate-50 text-slate-700 rounded-lg border border-1 border-slate-300 py-2 px-4"
                    disabled={loading}
                >
                    <i className='bx bxl-google bx-fw'></i> Continue with Google
                </button>
                {loading && <div className="mt-4 p-3 text-slate-500 text-center"><i className='bx bx-loader-circle bx-spin bx-fw'></i></div>}
                {message && (
                    <div className={`mt-4 p-3 rounded ${messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                        {message}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
