import React, { useState, useEffect } from 'react';

const TagInput = ({ value, onChange }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags((value || '').split(',').map(tag => tag.trim()));
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const newTag = e.target.value.trim();
      if (newTag && !tags.includes(newTag)) {
        const newTags = [...tags, newTag];
        setTags(newTags);
        onChange(newTags.join(', '));
      }
      e.target.value = '';
    }
  };

  const removeTag = (index) => {
    const newTags = tags.filter((tag, i) => i !== index);
    setTags(newTags);
    onChange(newTags.join(', '));
  };

  return (
    <div className="flex flex-wrap gap-2 border p-2 rounded-lg bg-slate-50">
      <div className="flex flex-wrap gap-2">
       {tags.map((tag, index) => (
<span key={index} className="flex items-center bg-blue-500 text-white px-4 py-1 rounded-full">
{tag}
<button type="button" className="ml-2 text-xs text-blue-400" onClick={() => removeTag(index)}>×</button>
</span>
))}
      </div>
      <input
        type="text"
        onKeyDown={handleKeyDown}
        placeholder="Add an interest and press enter"
        className="flex-grow bg-transparent border-none focus:outline-none"
      />
    </div>
  );
};

export default TagInput;
