import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route, 
  Routes,
  NavLink, 
  useLocation 
} from "react-router-dom";
import Login from "./Login";
import WatchPage from "./WatchPage";
import FeedsPage from "./FeedsPage";
import ForYou from "./ForYou";
import TopicPage from "./TopicPage";
import FeaturePage from "./FeaturePage";
import FollowPage from "./FollowPage";
import PromotionPage from "./PromotionPage";
import EventPage from "./EventsPage";
import ClientProfilePage from "./ClientProfilePage";
import UserSetting from "./UserSetting";
import ClientListPage from "./ClientsListPage";
import SinglePage from "./SinglePage";
import SingleTopic from "./SingleTopic";
import SingleEvent from "./SingleEvent";
import SearchPage from "./SearchPage";
import ClientList from "./ClientList";
import Manager from "./Manager";
import Apps from "./Apps";
import Home from "./Home";
import MTarot from './mini/Tarot/MTarot';
import Urlstory from './mini/Urlstory/Urlstory';

const App = () => {
  const [user, setUser] = useState(null);
  const [ad, setAD] = useState(null);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    const userData = sessionStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      if (user && user.name) {
        const firstWord = user.name.split(" ")[0];
        setAD(user.ad);
        setUser(user);
        setFirstName(firstWord);
      }
    }
    setLoading(false);
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
    setUser(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <AppContent
        user={user}
        ad={ad}
        firstName={firstName}
        handleLogout={handleLogout}
        setUser={setUser}
        setFirstName={setFirstName}
      />
    </Router>
  );
};

const AppContent = ({ user, ad, firstName, handleLogout, setUser, setFirstName }) => {
  const location = useLocation(); // Get the current location

  const shouldShowBottomNav = (pathname) => {
    const hiddenRoutes = ['/tarot', '/urlstory', '/', '/watch'];
    return !hiddenRoutes.includes(pathname);
  };
  
  const isDarkTheme = location.pathname === '/watch';

  return (
    <div className="container mx-auto relative">
      <div className="grid grid-cols-12">
        <div className="hidden lg:block col-span-2 border-r border-1 border-slate-200 p-5 sticky top-0 h-screen">
          <img src="/feeds-logo.png" className="w-24 mb-12 mt-5" />
          <ul className="space-y-4 raleway-600">
            <li>
              <NavLink
                to="/feedspage"
                className={({ isActive }) =>
                  isActive
                    ? "text-yellow-500 text-md"
                    : "text-slate-900 text-md"
                }
              >
                <i className="bx bxs-news bx-fw"></i>Feeds
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/follow"
                className={({ isActive }) =>
                  isActive
                    ? "text-yellow-500 text-md"
                    : "text-slate-900 text-md"
                }
              >
                <i className="bx bxs-heart bx-fw"></i>Following
              </NavLink>
            </li>
             <li>
              <NavLink
                to="/topic"
                className={({ isActive }) =>
                  isActive
                    ? "text-yellow-500 text-md"
                    : "text-slate-900 text-md"
                }
              >
                <i className="bx bxs-conversation bx-fw"></i>Topics
              </NavLink>
            </li>
          
                       <li>
              <NavLink
                to="/apps"
                className={({ isActive }) =>
                  isActive
                    ? "text-yellow-500 text-md"
                    : "text-slate-900 text-md"
                }
              >
                <i className="bx bxs-grid-alt bx-fw"></i>Apps
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/watch"
                className={({ isActive }) =>
                  isActive
                    ? "text-yellow-500 text-md"
                    : "text-slate-900 text-md"
                }
              >
                <i className="bx bxs-video bx-fw"></i>Watch
              </NavLink>
            </li>
            <li>
              <i className="bx bxs-briefcase bx-fw"></i>Jobs{" "}
              <span className="text-xs px-2 py-1 bg-slate-100 rounded-lg text-slate-500">
                soon
              </span>
            </li>
          </ul>
          {user ? (
            <ul className="space-y-5 mt-12 raleway-600">
              {ad ? (
                <li className="my-12">
                  <NavLink
                    to="/managers"
                    className={({ isActive }) =>
                      isActive
                        ? "text-yellow-500 text-md"
                        : "bg-yellow-500 text-white rounded-lg px-5 py-2 text-md"
                    }
                  >
                    <i className="bx bxs-edit bx-fw"></i>Posts
                  </NavLink>
                </li>
              ) : null}

              <li>
                <NavLink
                  to="/setting"
                  className={({ isActive }) =>
                    isActive
                      ? "text-yellow-500 text-md"
                      : "text-slate-900 text-md"
                  }
                >
                  <i className="bx bxs-user bx-fw"></i>
                  {firstName}
                </NavLink>
              </li>
              <li>
                <a href="/login" onClick={handleLogout}>
                  <i className="bx bxs-log-out-circle bx-fw"></i> Logout
                </a>
              </li>
            </ul>
          ) : (
            ""
          )}
          <p className="pr-5 text-sm bottom-5 absolute">
            Blog • <a title="Feeds Status" href="https://thefeeds.cronitorstatus.com/" target="_blank">Status</a> • Help • T&C Code of Conduct • Privacy © 2024 Feeds
            by Thepxn. • v1.0.0
          </p>
        </div>

        <Routes>
          <Route
            path="/login"
            element={<Login setUser={setUser} setFirstName={setFirstName} />}
          />
          <Route path="/feedspage" element={<FeedsPage />} />
          <Route path="/foryou" element={<ForYou />} />
          <Route path="/feature" element={<FeaturePage />} />
          <Route path="/follow" element={<FollowPage />} />
          <Route path="/promotion" element={<PromotionPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/list" element={<ClientListPage />} />
          <Route path="/events" element={<EventPage />} />
          <Route path="/setting" element={<UserSetting user={user} />} />
          <Route path="/managers" element={<Manager />} />
          <Route path="/tarot" element={<MTarot/>} />
          <Route path="/urlstory" element={<Urlstory/>} />
          <Route path="/apps" element={<Apps/>} />
          <Route path="/" element={<Home/> } />
          <Route path="u/:username" element={<ClientProfilePage />} />
          <Route path="/post/:postId" element={<SinglePage />} />
           <Route path="/topic/:postId" element={<SingleTopic />} />
          <Route path="/event/:postId" element={<SingleEvent />} />
          <Route
            path="*"
            element={
              <Routes>
                <Route path="/feedspage" element={<FeedsPage />} />
                <Route path="/topic" element={<TopicPage />} />
                <Route path="/foryou" element={<ForYou />} />
                <Route path="/feature" element={<FeaturePage />} />
                <Route path="/follow" element={<FollowPage />} />
                <Route path="/watch" element={<WatchPage />} />
                <Route path="/promotion" element={<PromotionPage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/list" element={<ClientListPage />} />
                <Route path="/events" element={<EventPage />} />
                <Route path="/setting" element={<UserSetting user={user} />} />
                <Route path="/managers" element={<Manager />} />
              </Routes>
            }
          />
        </Routes>
        <div className="lg:block hidden col-span-4 border-x border-1 border-slate-200 sticky top-0 h-screen">
          {user && <div> <h1 className="text-lg raleway-600 px-5 py-5 hidden uppercase border-b border-1 border-slate-200">
            <i className="bx bxs-user bx-fw"></i> Recommand to follow
          </h1>
          <ClientList user={user} /></div>}
        </div>
        {shouldShowBottomNav(location.pathname) && (
          <div className={`lg:hidden block border-slate-200 relative flex flex-col py-5 ${isDarkTheme ? 'bg-black text-white' : 'bg-white'}`}>
            {user && (
              <ul className={`grid grid-cols-5 gap-2 justify-around fixed bottom-0 w-full border-t border-1 py-5 pb-8 px-2 text-2xl ${isDarkTheme ? 'bg-black text-white border-black' : 'bg-white'}`}>
                <li>
                  <NavLink
                    to="/feedspage"
                    className={({ isActive }) =>
                      isActive
                        ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                        : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                    }
                  >
                    <i className="bx bxs-news"></i>
                    <span className="text-xs">feeds</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/apps"
                    className={({ isActive }) =>
                      isActive
                        ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                        : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                    }
                  >
                    <i className='bx bxs-grid-alt'></i>
                    <span className="text-xs">apps</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/topic"
                    className={({ isActive }) =>
                      isActive
                        ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                        : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                    }
                  >
                    <i className="bx bxs-conversation"></i>
                    <span className="text-xs">topic</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/follow"
                    className={({ isActive }) =>
                      isActive
                        ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                        : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                    }
                  >
                    <i className="bx bxs-heart"></i>
                    <span className="text-xs">following</span>
                  </NavLink>
                </li>
                {ad ? (
                  <li>
                    <NavLink
                      to="/managers"
                      className={({ isActive }) =>
                        isActive
                          ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                          : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                      }
                    >
                      <i className="bx bxs-edit"></i>
                      <span className="text-xs">Post</span>
                    </NavLink>
                  </li>
                ) : (
                  <li>
                    <NavLink
                      to="/watch"
                      className={({ isActive }) =>
                        isActive
                          ? `${isDarkTheme ? 'text-yellow-500' : 'text-yellow-500'} flex flex-col items-center`
                          : `${isDarkTheme ? 'text-white' : 'text-slate-900'} flex flex-col items-center`
                      }
                    >
                      <i className="bx bxs-video"></i>
                      <span className="text-xs">watch</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
