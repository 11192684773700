import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getSession } from './comp/session';
import { Link } from 'react-router-dom';

const ClientsListPage = () => {
    const [user, setUser] = useState(null);
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const user = JSON.parse(userData);
            setUser(user);
        } else {
            // Handle unauthenticated user
        }
    }, []);

    useEffect(() => {
        if (user) {
            fetchClients();
        }
    }, [user]);

    const fetchClients = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://www.pixelliongroup.com/feeds/GetClientsList.php', {
                params: { t: Date.now(), user_id: user.id }
            });
            setClients(response.data.clients);
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFollow = async (clientId, isFollowing) => {
        try {
            const url = isFollowing 
                ? 'https://www.pixelliongroup.com/feeds/UnfollowClient.php' 
                : 'https://www.pixelliongroup.com/feeds/FollowClient.php';
            const response = await axios.post(url, {
                user_id: user.id,
                client_id: clientId
            });
            if (response.data.status === 'success') {
                setClients(prevClients => prevClients.map(client => 
                    client.id === clientId ? { ...client, isFollowing: !isFollowing } : client
                ));
            }
        } catch (error) {
            console.error(`Error ${isFollowing ? 'unfollowing' : 'following'} client:`, error);
        }
    };

    return (
        <div className="col-span-6">
            <h1 className="text-lg raleway-600 px-10 px-5 py-5 uppercase border-b border-1 border-slate-200 sticky top-0 bg-white">
                Clients List
            </h1>
            {loading ? (
                <div className="text-center py-10 text-slate-400">
                    <i className='bx bx-loader-circle bx-spin bx-fw'></i> Loading...
                </div>
            ) : (
                <div className="">
                    {clients.map(client => (
                        <div key={client.id} className="p-5 border-b border-1 border-slate-200 flex items-center">
                            <img 
                                src={client.profile_picture} 
                                alt="Client Profile" 
                                className="w-10 h-10 rounded-full border border-1 border-slate-100 mr-4"
                            />
                            <div className="flex-1">
                                <p className="text-lg raleway-600">{client.name} {client.verify == 1 && <i className='bx bxs-bolt-circle text-yellow-500 '></i>}</p>
                                <p className="text-xs text-slate-500">
                                    <Link to={`/${client.username}`}>@{client.username}</Link> · {client.industry}
                                </p>
                            </div>
                            <button 
                                onClick={() => handleFollow(client.id, client.isFollowing)} 
                                className={`text-sm uppercase px-3 py-2 rounded-lg border border-1 border-slate-200 hover:bg-slate-50 ${client.isFollowing ? 'text-slate-500' : ' text-slate-500'}`}
                            >
                                {client.isFollowing ? 'Following' : 'Follow'}
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ClientsListPage;
