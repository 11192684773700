import React, { useState } from 'react';

const TheInteractive = ({ clientname, code, firstName, onSuccess }) => {
  const [digits, setDigits] = useState(Array(6).fill(''));
  const [message, setMessage] = useState('');
  const inputs = React.useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^\d$/.test(value) || value === '') {
      const newDigits = [...digits];
      newDigits[index] = value;
      setDigits(newDigits);

      // Move to the next input field
      if (value && index < 5) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleSubmit = () => {
    const userGuess = digits.join('');
    if (userGuess === code) {
      setMessage(`Congratulations, ${firstName}! You've guessed the right code.`);
      onSuccess(); // Notify parent component of success
    } else {
      setMessage('Sorry, that is not the correct code. Please try again.');
    }
  };

  React.useEffect(() => {
    if (digits.every((digit) => digit !== '')) {
      handleSubmit();
    }
  }, [digits]);

  return (
    <div className="flex justify-center items-center h-full ">
      <div className="bg-slate-100 p-10 rounded-lg w-full">
        <p className="w-full text-center mb-4">Interactive Component for {clientname}</p>
        <form className="flex flex-col items-center" onSubmit={(e) => e.preventDefault()}>
          <label className="block mb-2">Enter the 6-digit code:</label>
          <div className="flex space-x-2 mb-4">
            {digits.map((digit, index) => (
              <input
                key={index}
                type="text"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                maxLength="1"
                className="border p-2 text-center w-10 rounded"
                ref={(el) => (inputs.current[index] = el)}
              />
            ))}
          </div>
          {message && <p className="mt-4 text-lg">{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default TheInteractive;
