import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getSession, clearSession } from './comp/session';
import TheInteractive from './inter/TheInteractive';
import TheSimple from './inter/TheSimple';
import { format, formatDistanceToNowStrict, differenceInMinutes, parseISO } from 'date-fns';
import { toZonedTime, formatInTimeZone } from 'date-fns-tz';
import PhotoModal from './comp/PhotoModal';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const EventPage = () => {
    const [user, setUser] = useState(null);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serverTimezone, setServerTimezone] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const navigate = useNavigate();
    const [successMap, setSuccessMap] = useState({});
    const [firstName, setFirstName] = useState('');
    const [linkCopiedMap, setLinkCopiedMap] = useState({});
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const handleLinkCopy = (postId) => {
        const link = `https://feeds.ygn.me/post/${postId}`;
        navigator.clipboard.writeText(link).then(() => {
            setLinkCopiedMap(prevState => ({ ...prevState, [postId]: true }));
            setTimeout(() => {
                setLinkCopiedMap(prevState => ({ ...prevState, [postId]: false }));
            }, 2000); // Hide message after 2 seconds
        });
    };

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const user = JSON.parse(userData);
            const firstWord = user.name.split(' ')[0];
            setUser(user);
            setFirstName(firstWord);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        const sessionUser = getSession('user');
        if (sessionUser) {
            setUser(sessionUser);
            setLoading(true); // Add this line
            fetchPosts(sessionUser.id);
        } else {
            navigate('/login');
        }
    }, []);
    
     useEffect(() => {
        const userData = sessionStorage.getItem('user'); // Changed to session storage
        if (userData) {
            const user = JSON.parse(userData);
            const firstWord = user.name.split(' ')[0];
            setUser(user);
            setFirstName(firstWord);
            setLoading(false);
        } else {
            navigate('/login'); // Redirect to login if no session
        }
    }, [navigate]);


    const openModal = (photo) => {
        setSelectedPhoto(photo);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedPhoto(null);
        setModalIsOpen(false);
    };

    const fetchLinkPreview = async (url) => {
        try {
            const response = await axios.get(`https://www.pixelliongroup.com/link_preview_api.php?url=${encodeURIComponent(url)}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching link preview:', error);
            return null;
        }
    };

    const fetchPosts = async (userId, page = 1) => {
        setIsFetching(true);
        try {
            const response = await axios.get('https://www.pixelliongroup.com/feeds/PostEvent.php', {
                params: { t: Date.now(), user_id: userId, page, per_page: 10 }
            });

            const { posts, server_timezone } = response.data;

            // Fetch like counts, liked status, and comment counts for each post
            const postsWithCounts = await Promise.all(posts.map(async post => {
                const countsResponse = await axios.get('https://www.pixelliongroup.com/feeds/GetLikeCount.php', {
                    params: { post_id: post.id, user_id: userId }
                });

                const { like_count, liked, comment_count } = countsResponse.data;

                let linkPreview = null;
                if (post.type === 'link') {
                    linkPreview = await fetchLinkPreview(JSON.parse(post.content).link);
                }

                return { ...post, likeCount: like_count, liked, commentCount: comment_count, linkPreview };
            }));

            // Ensure no duplicates are added
            setPosts(prevPosts => {
                const postIds = new Set(prevPosts.map(post => post.id));
                const newPosts = postsWithCounts.filter(post => !postIds.has(post.id));
                return [...prevPosts, ...newPosts];
            });

            setServerTimezone(server_timezone);
            setHasMore(posts.length === 5); // If less than 5 posts were returned, we have reached the end
        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setIsFetching(false);
            setLoading(false);
        }
    };

    const handleLike = async (postId) => {
        const storedUser = JSON.parse(sessionStorage.getItem('user'));
        if (!storedUser) {
            console.error('No user found in session storage');
            return;
        }

        const post = posts.find(post => post.id === postId);
        if (!post) {
            console.error('Post not found');
            return;
        }

        const action = post.liked ? 'unlike' : 'like';

        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/LikePost.php', 
                { post_id: postId, user: storedUser }, 
                { withCredentials: true }
            );
            setPosts(prevPosts => prevPosts.map(post => 
                post.id === postId ? { 
                    ...post, 
                    likeCount: post.liked ? post.likeCount - 1 : post.likeCount + 1, 
                    liked: !post.liked 
                } : post
            ));
        } catch (error) {
            console.error(`Error ${action}ing post:`, error);
        }
    };

    const formatDate = (date) => {
        const postDate = toZonedTime(parseISO(date), serverTimezone);
        const now = new Date();
        const zonedNow = toZonedTime(now, serverTimezone);
        const minutesAgo = differenceInMinutes(zonedNow, postDate);

        if (minutesAgo < 60) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' minutes', 'm').replace(' minute', 'm');
        } else if (minutesAgo < 1440) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' hours', 'h').replace(' hour', 'h');
        } else {
            return formatInTimeZone(postDate, serverTimezone, 'd MMM yyyy');
        }
    };

    const createMarkup = (text) => {
        const html = text.replace(/\n/g, '<br />');
        return { __html: html };
    };

    const handleSuccess = (postId, photo, text) => {
        setSuccessMap(prevState => ({
            ...prevState,
            [postId]: { photo, text }
        }));
    };

   
    const loadMore = () => {
        if (!isFetching && hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        if (user) {
            fetchPosts(user.id, page);
        }
    }, [page, user]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
                loadMore();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    
        const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

  
    return (
        <div className="lg:col-span-6 col-span-12">
            <h1 className="lg:text-lg text-sm raleway-600 px-10 px-5 py-5 uppercase border-b border-1 border-slate-200 sticky top-0 bg-white">
                <i className='bx bxs-calendar-alt bx-fw'></i>Events
            </h1>
            {loading ? (
	             <div className="grid lg:grid-cols-2">
                <div className="animate-pulse p-5">
                    <div className="mb-4 flex items-center">
                        <div className="mr-4 h-12 w-12 rounded-full border border-slate-100 bg-slate-200"></div>
                        <div>
                            <div className="mr-4 mb-2 h-5 w-40 block border rounded-full bg-slate-200"></div>
                            <div className="mr-4 h-3 w-60 border rounded-full bg-slate-200"></div>
                        </div>
                    </div>
                    <div className="ml-14">
                        <div className="mb-2 h-60 w-full rounded-lg bg-slate-200"></div>
                        <div className="mb-4 h-20 rounded-lg bg-slate-200"></div>
                    </div>
                    <div className="ml-14">
                     </div>
                </div>
                
                <div className="animate-pulse p-5 hidden lg:block">
                    <div className="mb-4 flex items-center">
                        <div className="mr-4 h-12 w-12 rounded-full border border-slate-100 bg-slate-200"></div>
                        <div>
                            <div className="mr-4 mb-2 h-5 w-40 block border rounded-full bg-slate-200"></div>
                            <div className="mr-4 h-3 w-60 border rounded-full bg-slate-200"></div>
                        </div>
                    </div>
                    <div className="ml-14">
                        <div className="mb-2 h-60 w-full rounded-lg bg-slate-200"></div>
                        <div className="mb-4 h-20 rounded-lg bg-slate-200"></div>
                    </div>
                    <div className="ml-14">
                    </div>
                </div>
                
                </div>
            ) : (
                <div className="lg:grid lg:grid-cols-2 lg:gap-5  p-5 auto-rows-fr">
                    {posts.map(post => (
                        <div key={post.id} className="border p-5 lg:mb-0 mb-5 rounded-lg" >
                            
                            
                          

                            {post.type === 'event' && (() => {
                                let content;
                                try {
                                    const cleanedContent = post.content.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, " ");
                                    content = JSON.parse(cleanedContent);
                                } catch (error) {
                                    return <p className="text-gray-700 mb-4">Error loading event content</p>;
                                }

                                return (
                                    <div>
                                  
                                  
                                  
                                        <div>
                                        
                                        <div className="flex items-center mb-4">
                                {post.client_name ? (
                                    <>
                                        <img 
                                            src={post.client_profile_picture} 
                                            alt="{post.client_name}" 
                                            className="w-10 h-10 rounded-full border border-1 border-slate-100 lg:mr-4 mr-2"
                                        />
                                        <div>
                                            <p className="text-lg raleway-600">{post.client_name} {post.client_verify == 1 && <i className='bx bxs-bolt-circle text-yellow-500 '></i>} </p>
                                            <p className="text-xs text-slate-500"><Link to={`/u/${post.client_username}`}>
                                                @{post.client_username}
                                            </Link> · {post.client_industry} · {formatDate(post.created_at)}</p>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <p className="text-lg raleway-600">Client not available</p>
                                    </div>
                                )}
                            </div>
                                        
                                            <img src={content.photo} alt="Event" className="w-full rounded-lg mb-4" />
                                             <Link to={`/post/${post.id}`}><h2 className="raleway-600  text-2xl font-bold mb-2">{content.title}</h2></Link>
                                            <p className="text-slate-500 text-sm my-5"><i className='bx bxs-calendar bx-fw'></i>{content.date} <i className='bx bxs-time bx-fw' ></i>{content.time} <i className='bx bxs-info-circle bx-fw'></i>{content.price} <i className='bx bxs-buildings bx-fw'></i>{content.type}</p>
                                            <p className="text-slate-700">
                                                {truncateText(content.text, 20)}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })()}

                          
                                                                               </div>
                    ))}
                    {isFetching && (
                        <div className="text-center py-10 text-slate-400 col-span-2"><i className='bx bx-loader-circle bx-spin bx-fw'></i>Loading more...</div>
                    )}
                    {!hasMore && (
                        <div className="text-center py-10 text-slate-400 col-span-2">You reached all posts</div>
                    )}
                </div>
            )}
           
        </div>
    );
};

export default EventPage;
