// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, updatePassword } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyBBYqXEhSgpfNDNkLUBQopnO2PyIoYsNvM",
  authDomain: "feeds-f8861.firebaseapp.com",
  projectId: "feeds-f8861",
  storageBucket: "feeds-f8861.appspot.com",
  messagingSenderId: "438713548844",
  appId: "1:438713548844:web:244fca7abe6dcae6ec9189",
  measurementId: "G-JSMYMNEN2Y"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, updatePassword };
