import React from 'react';
import ExUrl from './ExUrl';
import { Link } from 'react-router-dom';
const Urlstory = () => {
  return (
    <div className="lg:col-span-6 col-span-12">
      <h1 className="lg:text-lg text-sm font-bold px-5 py-5 raleway-600 uppercase border-b border-gray-200 sticky top-0 bg-white">
        <a href="/apps"><i className='bx bx-chevron-left-circle bx-fw'></i></a>App / Urlstory <span className="absolute right-5 text-xl">
          <Link to={`/profile/urlstory`}>
            <i className='bx bxs-grid-alt'></i>
          </Link>
        </span>
      </h1>

      <ExUrl url="https://urlstory.ygn.me" />
    </div>
  );
};

export default Urlstory;
