import React from "react";

const CardDisplay = ({ card, onReset }) => {
  return (
    <div className="p-5 w-full  lg:grid grid-cols-2 gap-5 items-center">
     <div className="p-3 rounded-lg border w-full bg-slate-900 mb-5 lg:mb-0">
      <img className="w-full rounded-lg" src={card.img} />
     </div>
     <div className=" w-full h-full bg-slate-100 rounded-lg lg:p-10 p-5">
       <h2 className="text-2xl font-bold mb-2 uppercase  raleway-600">{card.name}</h2>
      <p className="mb-5 leading-7">{card.description}</p>
      <button
        className="bg-white rounded-lg border text-slate-600 px-4 py-2 w-full"
        onClick={onReset}
      >
        အခြားမေးခွန်းမေးမည်
      </button>
     </div>
    </div>
  );
};

export default CardDisplay;
