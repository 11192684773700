import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { formatDistanceToNowStrict, differenceInMinutes, parseISO } from 'date-fns';
import { toZonedTime, formatInTimeZone } from 'date-fns-tz';
import TheInteractive from './inter/TheInteractive';
import TheSimple from './inter/TheSimple';
import PhotoModal from './comp/PhotoModal';
import parse from 'html-react-parser';
import ReactPlayer from 'react-player';

// Memoize components
const MemoizedTheInteractive = React.memo(TheInteractive);
const MemoizedTheSimple = React.memo(TheSimple);
const MemoizedPhotoModal = React.memo(PhotoModal);

const SinglePage = () => {
    const { postId } = useParams();
    const [user, setUser] = useState(null);
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [replyText, setReplyText] = useState(''); // Separate state for reply text
    const [loading, setLoading] = useState(true);
    const [serverTimezone, setServerTimezone] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [successMap, setSuccessMap] = useState({});
    const [firstName, setFirstName] = useState('');
    const [linkCopied, setLinkCopied] = useState(false); // New state for link copied
    const [replyingTo, setReplyingTo] = useState(null); // New state for tracking replies
    const navigate = useNavigate();
    const [commentsLoading, setCommentsLoading] = useState(false);

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    useEffect(() => {
        const userData = sessionStorage.getItem('user'); // Changed to session storage
        if (userData) {
            const user = JSON.parse(userData);
            
            const firstWord = user.name.split(' ')[0];
            setUser(user);
            setFirstName(firstWord);
            setLoading(false);
        } else {
            navigate('/login'); // Redirect to login if no session
        }
    }, [navigate]);

    const fetchPostData = useCallback(async () => {
        if (!user) return;

        setLoading(true);
        try {
            const response = await axios.get(`https://www.pixelliongroup.com/feeds/SinglePost.php?postid=${postId}`);
            const { post, server_timezone } = response.data;

            if (post) {
                const countsResponse = await axios.get('https://www.pixelliongroup.com/feeds/GetLikeCount.php', {
                    params: { post_id: post.id, user_id: user.id, t: Date.now() }
                });

                const { like_count, liked, comment_count } = countsResponse.data;

                let linkPreview = null;
                if (post.type === 'link') {
                    linkPreview = await fetchLinkPreview(JSON.parse(post.content).link);
                }

                const clientIds = [post.client_id];
                const clientData = await fetchClients(clientIds);

                setPost({
                    ...post,
                    likeCount: like_count,
                    liked,
                    commentCount: comment_count, // Include comment count
                    linkPreview,
                    client: clientData[post.client_id]
                });

                setServerTimezone(server_timezone);
                fetchComments(post.id); // Fetch comments for the post
            }
        } catch (error) {
            console.error('Error fetching post:', error);
        } finally {
            setLoading(false);
        }
    }, [postId, user]);

    useEffect(() => {
        if (user) {
            fetchPostData();
        }
    }, [fetchPostData, user, postId]); // Include postId as a dependency

    const fetchComments = useCallback(async (postId) => {
        setCommentsLoading(true); // Start loading
        try {
            const response = await axios.get(`https://www.pixelliongroup.com/feeds/GetComments.php?post_id=${postId}&t=${Date.now()}`);
            if (response.data && Array.isArray(response.data)) {
                const commentsMap = {};
                const commentLikePromises = response.data.map(async (comment) => {
                    const likeResponse = await axios.get(`https://www.pixelliongroup.com/feeds/GetCommentLikeStatus.php`, {
                        params: { comment_id: comment.id, user_id: user.id }
                    });
                    const { like_count, liked } = likeResponse.data;
                    return { ...comment, likeCount: like_count, liked };
                });

                const commentsWithLikes = await Promise.all(commentLikePromises);

                commentsWithLikes.forEach(comment => {
                    comment.replies = [];
                    commentsMap[comment.id] = comment;
                });

                const nestedComments = [];
                Object.values(commentsMap).forEach(comment => {
                    if (comment.parent_id === 0) {
                        nestedComments.push(comment);
                    } else {
                        if (commentsMap[comment.parent_id]) {
                            commentsMap[comment.parent_id].replies.push(comment);
                        }
                    }
                });

                setComments(nestedComments);
            } else {
                setComments([]); // Ensure comments are reset if no valid response
                console.error('No comments returned from the API');
            }
        } catch (error) {
            setComments([]); // Ensure comments are reset on error
            console.error('Error fetching comments:', error);
        } finally {
            setCommentsLoading(false); // Stop loading
        }
    }, [user]);

    const handleLikeComment = async (commentId) => {
        if (!user) return;

        const allComments = comments.flatMap(comment => [comment, ...comment.replies]);
        const comment = allComments.find(c => c.id === commentId);
        if (!comment) return;

        const action = comment.liked ? 'unlike' : 'like';

        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/LikeComment.php', 
                { comment_id: commentId, user_id: user.id }, 
                { withCredentials: true }
            );

            if (response.data.success) {
                setComments(prevComments => prevComments.map(c => 
                    c.id === commentId ? { ...c, likeCount: c.liked ? c.likeCount - 1 : c.likeCount + 1, liked: !c.liked } : 
                    { ...c, replies: c.replies.map(r => 
                        r.id === commentId ? { ...r, likeCount: r.liked ? r.likeCount - 1 : r.likeCount + 1, liked: !r.liked } : r
                    )}
                ));
            }
        } catch (error) {
            console.error(`Error ${action}ing comment:`, error);
        }
    };

    const fetchLinkPreview = async (url) => {
        try {
            const response = await axios.get(`https://www.pixelliongroup.com/link_preview_api.php?url=${encodeURIComponent(url)}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching link preview:', error);
            return null;
        }
    };

    const fetchClients = async (clientIds) => {
        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/ClientService.php', { clientIds });
            const clients = response.data.clients || {};
            return clients;
        } catch (error) {
            console.error('Error fetching clients:', error);
            return {};
        }
    };

    const openModal = (photo) => {
        setSelectedPhoto(photo);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedPhoto(null);
        setModalIsOpen(false);
    };

    const handleLike = async (postId) => {
        const storedUser = JSON.parse(sessionStorage.getItem('user'));
        if (!storedUser) {
            console.error('No user found in session storage');
            return;
        }

        const action = post.liked ? 'unlike' : 'like';

        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/LikePost.php', 
                { post_id: postId, user: storedUser }, 
                { withCredentials: true }
            );
            console.log(`${action} response:`, response.data);
            setPost(prevPost => ({
                ...prevPost, 
                likeCount: post.liked ? post.likeCount - 1 : post.likeCount + 1, 
                liked: !post.liked
            }));
        } catch (error) {
            console.error(`Error ${action}ing post:`, error);
        }
    };

    const formatDate = (date) => {
        const postDate = toZonedTime(parseISO(date), serverTimezone);
        const now = new Date();
        const zonedNow = toZonedTime(now, serverTimezone);
        const minutesAgo = differenceInMinutes(zonedNow, postDate);

        if (minutesAgo < 60) {
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' minutes', 'm').replace(' minute', 'm');
        } else if (minutesAgo < 1440) { // 1440 minutes in a day
            return formatDistanceToNowStrict(postDate, { addSuffix: true }).replace(' hours', 'h').replace(' hour', 'h');
        } else {
            return formatInTimeZone(postDate, serverTimezone, 'd MMM yyyy');
        }
    };

    const handleSuccess = (postId, photo, text) => {
        setSuccessMap(prevState => ({
            ...prevState,
            [postId]: { photo, text }
        }));
    };

    const renderContentAA = (content) => {
        return parse(content, {
            replace: (domNode) => {
                if (domNode.name && domNode.name.toLowerCase() === 'thesimple') {
                    const clientname = domNode.attribs.clientname;
                    return <MemoizedTheSimple clientname={clientname} />;
                }
            }
        });
    };

    const renderContent = (content, postId) => {
        let parsedContent;
        try {
            parsedContent = JSON.parse(content);
            parsedContent.inter = parsedContent.inter.replace(/{firstName}/g, firstName);
        } catch (error) {
            console.error('Error parsing content:', error);
            return null;
        }

        return parse(parsedContent.inter, {
            replace: (domNode) => {
                if (domNode.name && domNode.name.toLowerCase() === 'theinteractive') {
                    const { clientname, code } = domNode.attribs;
                    return (
                        <MemoizedTheInteractive
                            clientname={clientname}
                            code={code}
                            firstName={firstName}
                            onSuccess={() => {
                                handleSuccess(postId, parsedContent.photo, parsedContent.text);
                            }}
                        />
                    );
                }
            }
        });
    };

    const handleAddComment = async (e, parentId = 0) => {
        e.preventDefault();

        const text = parentId === 0 ? commentText : replyText; // Use appropriate text

        try {
            const response = await axios.post('https://www.pixelliongroup.com/feeds/AddComment.php', {
                post_id: postId,
                user_id: user.id,
                comment_text: text,
                parent_id: parentId
            });

            if (response.data.success) {
                if (parentId === 0) {
                    setCommentText('');
                } else {
                    setReplyText('');
                    setReplyingTo(null); // Reset reply state
                }
                fetchComments(postId); // Refresh comments
            } else {
                console.error('Error adding comment');
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const handleLinkCopy = () => {
        const link = `https://feeds.ygn.me/post/${postId}`;
        navigator.clipboard.writeText(link).then(() => {
            setLinkCopied(true);
            setTimeout(() => {
                setLinkCopied(false);
            }, 2000); // Hide message after 2 seconds
        });
    };

    if (loading) {
        return (
            <div className="text-center py-10 text-slate-400 lg:col-span-6 col-span-12">
                <i className='bx bx-loader-circle bx-spin bx-fw'></i>
            </div>
        )
    }

    if (!post) {
        return null; // Do not render anything if the post is not found
    }

    return (
	    
        <div className="lg:col-span-6 col-span-12">
            <h1 className="lg:text-lg text-sm raleway-600 px-10 px-5 py-5 uppercase border-b border-1 border-slate-200 sticky top-0 bg-white">
                <button className="uppercase" onClick={goBack}><i className='bx bx-chevron-left-circle bx-fw'></i> Back Feeds</button>
            </h1>
            {loading ? (
                <div className="text-center py-10 text-slate-400 col-span-6"><i className='bx bx-loader-circle bx-spin bx-fw'></i></div>
            ) : null}
            <div className="p-5 border-b border-1 border-slate-200">
                <div className="flex items-center mb-4">
                    {post.client ? (
                        <>
                            <img 
                                src={post.client.profile_picture} 
                                alt="Client Profile" 
                                className="w-10 h-10 rounded-full border border-1 border-slate-100 lg:mr-4 mr-2"
                            />
                            <div>
                                <p className="text-lg raleway-600">{post.client.name} {post.client.verify == 1 && <i className='bx bxs-bolt-circle text-yellow-500 '></i>} </p>
                                <p className="text-xs text-slate-500"><Link to={`/u/${post.client.username}`}>@{post.client.username}</Link> · {post.client.industry} · {formatDate(post.created_at)}</p>
                            </div>
                        </>
                    ) : (
                        <div>
                            <p className="text-lg raleway-600">Client not available</p>
                        </div>
                    )}
                </div>
                {post.type === 'text' && (
                    <p style={{ whiteSpace: 'pre-line' }} className="lg:pl-14 text-gray-700 mb-4 leading-relaxed text-lg">{post.content.replace(/(\r\n|\n|\r)/gm, "\\n")}</p>
                )}
                {post.type === 'photo_text' && (
                    <div className="lg:ml-14">
                        {(() => {
                            let content;
                            try {
                                content = JSON.parse(post.content.replace(/(\r\n|\n|\r)/gm, "\\n"));
                            } catch (error) {
                                console.error('Error parsing content:', error);
                                return <p className="text-gray-700 mb-4">Error loading content</p>;
                            }

                            if (Array.isArray(content.photos)) {
                                return (
                                    <>
                                        <div className="grid-cols-2 grid gap-1 mb-2">
                                            {content.photos.map((photo, index) => (
                                                <img 
                                                    key={index} 
                                                    src={photo} 
                                                    alt={`Photo ${index + 1}`} 
                                                    className="w-full object-cover rounded-lg cursor-pointer border border-1 border-slate-100"
                                                    onClick={() => openModal(photo)}
                                                />
                                            ))}
                                        </div>
                                    </>
                                );
                            } else if (content.photo) {
                                return (
                                    <>
                                        <img 
                                            src={content.photo} 
                                            alt="Photo" 
                                            className="mb-2 w-full object-cover rounded-lg cursor-pointer border border-1 border-slate-100"
                                            onClick={() => openModal(content.photo)}
                                        />
                                    </>
                                );
                            } else {
                                return <p className="text-gray-700 mb-4">No photos available</p>;
                            }
                        })()}
                        <p style={{ whiteSpace: 'pre-line' }} className="text-gray-900 mb-4 leading-relaxed text-lg p-5 bg-slate-50 rounded-lg">{JSON.parse(post.content.replace(/(\r\n|\n|\r)/gm, "\\n")).text}</p>
                    </div>
                )}
                {post.type === 'html' && (
                    <div className="lg:ml-14 mb-5">
                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    </div>
                )}
                {post.type === 'inter_text' && (
                    <div className="lg:ml-14 mb-5">
                        {successMap[post.id] ? (
                            <div>
                                <img src={successMap[post.id].photo} alt="Success" className="w-full rounded-lg" />
                                <p className="mt-4 text-lg">{successMap[post.id].text}</p>
                            </div>
                        ) : (
                            renderContent(post.content, post.id)
                        )}
                    </div>
                )}
                {post.type === 'inter' && (
                    <div className="lg:ml-14 mb-5">
                        {renderContentAA(post.content)}
                    </div>
                )}
                {post.type === 'event' && (() => {
                    let content;
                    try {
                        const cleanedContent = post.content.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, " ");
                        content = JSON.parse(cleanedContent);
                    } catch (error) {
                        return <p className="text-gray-700 mb-4">Error loading event content</p>;
                    }

                    return (
                        <div className="lg:ml-14 mb-5">
                            <p className="my-5">
                                {content.content}
                            </p>
                            <div className="border p-5 rounded-lg">
                                <img src={content.photo} alt="Event" className="w-full rounded-lg mb-4" />
                                <a href={content.url}><h2 className="raleway-600 text-3xl font-bold mb-2">{content.title}</h2></a>
                                <p className="text-slate-500 text-sm mb-2"><i className='bx bxs-calendar bx-fw'></i>{content.date} <i className='bx bxs-time bx-fw'></i>{content.time} <i className='bx bxs-info-circle bx-fw'></i>{content.price} <i className='bx bxs-buildings bx-fw'></i>{content.type}</p>
                                <p className="text-slate-700">
                                    {content.text}
                                </p>
                            </div>
                        </div>
                    );
                })()}
                {post.type === 'link' && post.linkPreview && (
                    <div className="lg:ml-14">
                        {JSON.parse(post.content).text}
                        <a href={post.content} className="">
                            <div className="border hover:bg-white mb-5 hover:ring hover:ring-5 hover:ring-slate-200 p-4 mt-2 rounded-lg grid grid-cols-2 gap-10">
                                <div className="col-span-1">{post.linkPreview.image && <img src={post.linkPreview.image} alt="Preview" className="rounded-lg w-full h-48 object-cover" />}</div>
                                <div className="col-span-1">
                                    <h2 className="text-lg font-bold mt-2">{post.linkPreview.title}</h2>
                                    <p className="text-sm text-gray-600">{post.linkPreview.description}</p>
                                    <p className="text-sm text-gray-600 mt-5"><i className='bx bx-world bx-fw'></i>{post.linkPreview.domain}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                )}
                {post.type === 'video' && (
                    <div className="lg:ml-14 mb-5">
                        <div className="rounded-lg bg-black overflow-hidden w-full">
                            <ReactPlayer className="react-player w-full h-full" width="100%" height="100%" muted={true} playing={true} loop={true} controls={true} url={JSON.parse(post.content).video} />
                        </div>
                    </div>
                )}
                <div className="lg:ml-14 space-x-2 text-slate-500 text-lg">
                    <button onClick={() => handleLike(post.id)}>
                        <i className={`bx bxs-like bx-fw hover:text-blue-500 ${post.liked ? 'text-blue-500 ' : ''}`}></i>
                    </button>
                    {post.likeCount}
                    <Link to={`/post/${post.id}`}><i className='bx bx-fw bxs-message-square-dots hover:text-slate-700'></i>{post.commentCount}</Link>
                    <i className='bx bx-fw bxs-paper-plane hover:text-slate-700 cursor-pointer' onClick={handleLinkCopy}></i>
                    {linkCopied && <span className="bg-slate-50 rounded-lg px-3 py-2 text-sm"><i className='bx bx-link-alt bx-fw'></i>Link copied</span>}
                </div>
            </div>

            {/* Comments Section */}
            <div className="p-5 border-b border-1 border-slate-200">
                <h2 className="text-lg lg:ml-14 raleway-600 mb-5"><i className='bx bx-fw bxs-message-square-dots'></i>Comments</h2>
             
             {commentsLoading ? (
    <div className="text-center py-10 text-slate-400 col-span-6">
        <i className='bx bx-loader-circle bx-spin bx-fw'></i>Loading comments...
    </div>
) : (
    comments.length > 0 ? comments.map(comment => {
        const isApproved = comment.status === 'approved';
        const isPendingByCurrentUser = comment.status === 'pending' && comment.user_id === user.id;
        return (isApproved || isPendingByCurrentUser) ? (
            <div key={comment.id} className="lg:ml-14 mt-2">
                <div className="flex items-start space-x-2">
                    <img src={comment.profile_image} alt={`${comment.user_name}'s profile`} className="w-10 h-10 rounded-full border border-gray-200"/>
                    <div className="bg-slate-50 rounded-lg p-5 w-full">
                        <p className="font-semibold">{comment.user_name} <span className="text-xs text-gray-500"> {formatDate(comment.created_at)}</span></p>
                        <p>{comment.comment_text}</p>
                        {comment.status === 'pending' && comment.user_id === user.id && <p className="text-xs text-gray-500 mt-1">Pending</p>}
                        <div className="flex items-center space-x-2 mt-2">
                            <button onClick={() => handleLikeComment(comment.id)} className={`text-sm ${comment.liked ? 'text-blue-500' : 'text-gray-500'} hover:underline`}>
                                {comment.liked ? 'Unlike' : 'Like'}
                            </button>
                            <span className="text-xs text-gray-500">{comment.likeCount} likes</span>
                            <button onClick={() => setReplyingTo(comment.id)} className="text-sm text-blue-500 hover:underline">Reply</button>
                        </div>
                    </div>
                </div>
                {comment.replies && comment.replies.map(reply => (
                    <div key={reply.id} className="lg:ml-12 ml-5 mt-2 flex items-start space-x-2">
                        <img src={reply.profile_image} alt={`${reply.user_name}'s profile`} className="w-8 h-8 rounded-full border border-gray-200"/>
                        <div className="bg-slate-100 rounded-lg p-5 w-full">
                            <p className="font-semibold">{reply.user_name} <span className="text-xs text-gray-500"> {formatDate(reply.created_at)}</span></p>
                            <p>{reply.comment_text}</p>
                            {reply.status === 'pending' && reply.user_id === user.id && <p className="text-xs text-gray-500 mt-1">Pending</p>}
                            <div className="flex items-center space-x-2 mt-2">
                                <button onClick={() => handleLikeComment(reply.id)} className={`text-sm ${reply.liked ? 'text-blue-500' : 'text-gray-500'} hover:underline`}>
                                    {reply.liked ? 'Unlike' : 'Like'}
                                </button>
                                <span className="text-xs text-gray-500">{reply.likeCount} likes</span>
                            </div>
                        </div>
                    </div>
                ))}
                {replyingTo === comment.id && (
                    <form onSubmit={(e) => handleAddComment(e, comment.id)} className="ml-12 mt-2">
                        <textarea
                            value={replyText}
                            onChange={(e) => setReplyText(e.target.value)}
                            placeholder={`Reply as ${firstName}`}
                            required
                            className="w-full p-2 border border-gray-300 rounded-lg"
                        />
                        <button type="submit" className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg">Submit</button>
                    </form>
                )}
            </div>
        ) : null;
    }) : <p className="my-8 text-gray-500 w-full text-center"><i className='bx bx-fw bxs-message-square'></i> No comments yet.</p>
)}

<form onSubmit={(e) => handleAddComment(e)} className="lg:ml-14 mt-4 mb-10">
    <textarea
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        placeholder={`Add a comment as ${firstName}`}
        required
        className="w-full p-2 border border-gray-300 rounded-lg"
    />
    <button type="submit" className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg">Submit</button>
</form>

            </div>

            <MemoizedPhotoModal isOpen={modalIsOpen} onClose={closeModal} photo={selectedPhoto} />
        </div>
    );
};

export default SinglePage;
