import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useParams, Link, useNavigate } from 'react-router-dom';
const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [text, setText] = useState("");
    const [link, setLink] = useState("");
    const [type, setType] = useState("text"); // Default to text type
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [userId, setUserId] = useState("");
    const [activeTab, setActiveTab] = useState("text");
    const [responseMessage, setResponseMessage] = useState("");
      const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState('');
   const navigate = useNavigate();
     const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userData = localStorage.getItem("user");
        if (userData) {
            const user = JSON.parse(userData);
            setUserId(user.id);
        }
    }, []);

    useEffect(() => {
        if (!userId) return;

        const fetchClients = async () => {
            try {
                const response = await axios.get(`https://www.pixelliongroup.com/feeds/ManagerList.php?user_id=${userId}&t=${Date.now()}`);
                if (response.data.status === 'success') {
                    setClients(response.data.clients);
                    setClientId(response.data.clients.length > 0 ? response.data.clients[0].id : "");
                } else {
                    console.error('Error fetching clients:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, [userId]);
    
       useEffect(() => {
        const userData = sessionStorage.getItem('user'); // Changed to session storage
        if (userData) {
            const user = JSON.parse(userData);
            const firstWord = user.name.split(' ')[0];
            setUser(user);
            setFirstName(firstWord);
            setLoading(false);
        } else {
            navigate('/login'); // Redirect to login if no session
        }
    }, [navigate]);

   const onDrop = useCallback((acceptedFiles) => {
    const acceptedTypes = ['image/gif', 'image/jpeg', 'image/png', 'video/mp4', 'video/webm', 'video/ogg'];
    const file = acceptedFiles[0];

    if (acceptedTypes.includes(file.type)) {
        setFile(file);
        if (file.type.startsWith('video/')) {
            setType('video');
        } else {
            setType('photo_text');
        }
    } else {
        setResponseMessage('File type not accepted. Only GIF, JPEG, PNG, MP4, WEBM, and OGG are allowed.');
    }
}, []);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const handleLinkChange = (e) => {
        setLink(e.target.value);
    };

    const handleClientChange = (e) => {
        setClientId(e.target.value);
    };

    const handleUpload = async () => {
    const formData = new FormData();
    formData.append("client_id", clientId);
    formData.append("type", type);
    formData.append("text", text);

    if (activeTab === "media") {
        formData.append("file", file);
        formData.append("text", text);
    } else if (activeTab === "link") {
        formData.append("link", link);
        formData.append("text", text);
        formData.append("type", "link");
    } else if (activeTab === "text") {
        formData.append("text", text);
        formData.append("type", "text");
    } else if (activeTab === "video") {
        formData.append("file", file);
        formData.append("text", text);
}
    try {
        const response = await axios.post('https://www.pixelliongroup.com/feeds/upload.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        });
        setResponseMessage(response.data.message);
        console.log(response.data);
    } catch (error) {
        setResponseMessage("Error uploading file");
        console.error('Error uploading file:', error);
    }
};


    return (
        <div className="lg:col-span-6 col-span-12 lg:mb-0 mb-10">
            <div className="lg:text-lg text-sm raleway-600 px-10 px-5 py-5 uppercase border-b border-1 border-slate-200  bg-white ">
                <div className="w-full lg:w-fit border border-1 border-slate-300 rounded-lg px-5 py-3 ">
                    <select value={clientId} onChange={handleClientChange} className="focus:outline-none">
                        {clients.map(client => (
                            <option key={client.id} value={client.id}>{client.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            
            {uploadProgress > 0 && (
                <div className="-mt-4">
                    <progress className="w-full h-1 rounded" value={uploadProgress} max="100">{uploadProgress}%</progress>
                </div>
            )}

            <div className="m-5 border rounded-lg">
                <div className="uppercase space-x-2 border-b p-2">
                    <button onClick={() => setActiveTab("text")} className={`p-2 rounded ${activeTab === "text" ? "bg-slate-100 text-black" : "bg-white"}`}><i className='bx bx-text bx-sm bx-fw'></i></button>
                    <button onClick={() => setActiveTab("media")} className={`p-2 rounded ${activeTab === "media" ? "bg-slate-100 text-black" : "bg-white"}`}><i className='bx bxs-image-alt bx-sm bx-fw' ></i></button>
                    <button onClick={() => setActiveTab("link")} className={`p-2 rounded ${activeTab === "link" ? "bg-slate-100 text-black" : "bg-white"}`}><i className='bx bx-link bx-sm bx-fw' ></i></button>
                    <button onClick={() => setActiveTab("video")} className={`p-2 rounded ${activeTab === "video" ? "bg-slate-100 text-black" : "bg-white"}`}><i className='bx bxs-video bx-sm bx-fw'></i></button>

                </div>

                {activeTab === "text" && (
                    <div>
                        <textarea
                            className="focus:outline-none w-full border-none p-5"
                            placeholder="Enter text"
                            value={text}
                            onChange={handleTextChange}
                            rows="4"
                            cols="50"
                        />
                        <div className="border-t p-2 bg-slate-50 rounded-b">
                            <button className="py-2 bg-slate-500 text-white rounded-lg w-1/3 right-5" onClick={handleUpload}>Post</button>
                        </div>
                    </div>
                )}

                {activeTab === "media" && (
                    <div>
                        <div {...getRootProps()} className="p-5 bg-slate-50 rounded-lg m-5 cursor-pointer text-center">
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <p>Drop the files here...</p>
                            ) : (
                                <p><i className='bx bxs-image-alt bx-sm bx-fw' ></i> Drag 'n' drop photo here, or click to select photo</p>
                            )}
                        </div>
                        {file && (
                            <div className="mt-4">
                                <img src={URL.createObjectURL(file)} alt="preview" className="w-full object-contain" />
                                <textarea type="text" placeholder="Enter text" value={text} onChange={handleTextChange} className="block w-full p-5 rounded focus:outline-none"></textarea>
                            </div>
                        )}
                        <div className="border-t p-2 bg-slate-50 rounded-b">
                            <button className="py-2 bg-slate-500 text-white rounded-lg w-1/3 right-5" onClick={handleUpload}>Post</button>
                        </div>
                    </div>
                )}
                
                {activeTab === "video" && (
    <div>
        <div {...getRootProps()} className="p-5 bg-slate-50 rounded-lg m-5 cursor-pointer text-center">
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop the files here...</p>
            ) : (
                <p><i className='bx bxs-video bx-sm bx-fw' ></i> Drag 'n' drop video here, or click to select video</p>
            )}
        </div>
        {file && (
            <div className="mt-4">
                <video controls className="w-full object-contain">
                    <source src={URL.createObjectURL(file)} type={file.type} />
                </video>
                <textarea type="text" placeholder="Enter text" value={text} onChange={handleTextChange} className="block w-full p-5 rounded focus:outline-none"></textarea>
            </div>
        )}
        <div className="border-t p-2 bg-slate-50 rounded-b">
            <button className="py-2 bg-slate-500 text-white rounded-lg w-1/3 right-5" onClick={handleUpload}>Post</button>
        </div>
    </div>
)}


                {activeTab === "link" && (
                    <div>
                        <input
                            type="url"
                            placeholder="https://www.yourdomain.com"
                            value={link}
                            onChange={handleLinkChange}
                            className="focus:outline-none w-full px-5 py-2 border-none"
                        />
                        <textarea
                            placeholder="Enter text"
                            value={text}
                            onChange={handleTextChange}
                            className="focus:outline-none w-full p-5 border-none"
                        />
                        <div className="border-t p-2 bg-slate-50 rounded-b">
                            <button className="py-2 bg-slate-500 text-white rounded-lg w-1/3 right-5" onClick={handleUpload}>Post</button>
                        </div>
                    </div>
                )}
            </div>
                          
            {responseMessage && (
                <div className="flex justify-center">
                    <div className="text-center text-sm bg-slate-50 w-fit px-10 py-2 rounded-full" style={{ marginTop: '10px', color: responseMessage === 'Post submitted successfully' ? 'green' : 'red' }}>
                        {responseMessage}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
